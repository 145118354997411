//@flow
import React from 'react'

type Props = {|
  children?: React.Node,
  isPrivate: boolean,
|}

const BaseLayout = (props: Props) => (
  <div
    className={`App ${!props.isPrivate ? 'App-body__register-form' : ''}`}
    id='app-root'
  >
    <div className='App-body'>{props.children}</div>
  </div>
)

export default BaseLayout
