import { useEffect } from 'react'

export const KEY_LOCAL_STORAGE_USER_STATE = 'user-state'
export const KEY_LOCAL_STORAGE_MAIN_STATE = 'main-state'

export function usePersistedContext(key = KEY_LOCAL_STORAGE_USER_STATE) {
  const persistedContext = localStorage.getItem(key)
  return persistedContext ? JSON.parse(persistedContext) : null
}

export function usePersistedReducer(
  [state, dispatch],
  key = KEY_LOCAL_STORAGE_USER_STATE
) {
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [state])
  return [state, dispatch]
}
