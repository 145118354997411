// @flow
import React from 'react'

type Props = {|
  isDisabled: Boolean,
  error: object,
  onBlur: () => void,
  inputRef: () => void,
  defaultValue: string,
  maxLength: string,
  name: string,
|}

export const UrlInput = ({
  error,
  isDisabled,
  onBlur,
  defaultValue,
  maxLength,
  name,
  inputRef,
}: Props) => {
  const isError = error && error[name]

  return (
    <div
      className={`product-form__attribute-url ${
        isDisabled ? 'form__auto-complete--disabled' : ''
      }
        ${isError ? 'product-form__attribute-url--error' : ''}
      `}
    >
      <label className='product-form__text'>{'Http:// '}</label>
      <input
        className={`form__textbox
        ${isDisabled ? 'form__textbox--disabled' : ''}`}
        type='text'
        defaultValue={defaultValue}
        onBlur={onBlur}
        ref={inputRef}
        name={name}
        maxLength={maxLength}
        disabled={isDisabled}
      />
    </div>
  )
}

// Defined default Props
UrlInput.defaultProps = {
  isError: false,
  type: 'text',
  maxlength: 500,
  inputRef: () => {},
}

export default UrlInput
