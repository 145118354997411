const ROUTER = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  POST: '/post/:id',
  ADD_POST: '/post/add',
  EDIT_POST: (id) => `/post/${id}`,

  PROGRAMS: '/programs',
  PROGRAM: '/program/:id',
  ADD_PROGRAM: '/program/add',
  EDIT_PROGRAM: (id) => `/program/${id}`,

  RELEASES: '/releases',
  RELEASE: '/release/:id',
  ADD_RELEASE: '/release/add',
  EDIT_RELEASE: (id) => `/release/${id}`,

  WORKOUTS: '/workouts',
  WORKOUTS_ROUTER: '/workouts/:productId',
  ADD_WORKOUT: (productId) => `/workout/${productId}/add`,
  ADD_WORKOUT_ROUTER: '/workout/:productId/add',
  EDIT_WORKOUT_ROUTER: '/workout/:productId/:id',
  EDIT_WORKOUT: (id, productId) => `/workout/${productId}/${id}`,

  PRODUCTS: '/products',
  PRODUCT: '/product/:id',
  ADD_PRODUCT: '/product/add',
  EDIT_PRODUCT: (id) => `/product/${id}`,

  PRESENTERS: '/presenters',
  PRESENTER: '/presenter/:id',
  ADD_PRESENTER: '/presenter/add',
  EDIT_PRESENTER: (id) => `/presenter/${id}`,

  MUSIC_LICENSINGS: '/music-licensing',
  MUSIC_LICENSING: '/music-licensing/:id',
  ADD_MUSIC_LICENSING: '/music-licensing/add',
  EDIT_MUSIC_LICENSING: (id) => `/music-licensing/${id}`,

  MOVES: '/moves',
  MOVE: '/move/:id',
  ADD_MOVE: '/move/add',
  EDIT_MOVE: (id) => `/move/${id}`,

  EQUIPMENTS: '/equipments',
  EQUIPMENT: '/equipment/:id',
  ADD_EQUIPMENT: '/equipment/add',
  EDIT_EQUIPMENT: (id) => `/equipment/${id}`,

  PLANS: '/plans',
  PLAN: '/plan/:id',
  ADD_PLAN: '/plan/add',
  EDIT_PLAN: (id) => `/plan/${id}`,

  COLLECTIONS: '/collections',
  COLLECTION: '/collection/:id',
  ADD_COLLECTION: '/collection/add',
  EDIT_COLLECTION: (id) => `/collection/${id}`,

  REPORTS: '/reports',
  ADD_REPORT: '/report/add',

  PUBLISHING: '/publishing',

  PUBLISH_PLANS: '/publish-plans',

  TIERS: '/tiers',
  TIER: '/tiers/:id',
  ADD_TIER: '/tiers/add',
  EDIT_TIER: (id) => `/tiers/${id}`,

  TIER_CHARGIFY_PRODUCTS: '/tier-chargify-product',
  TIER_CHARGIFY_PRODUCT: '/tier-chargify-product/:id',
  ADD_TIER_CHARGIFY_PRODUCT: '/tier-chargify-product/add',
  EDIT_TIER_CHARGIFY_PRODUCT: (id) => `/tier-chargify-product/${id}`,
  EDIT_TIER_CHARGIFY_PRODUCTS: '/tier-chargify-product/edit',
  DELETE_TIER_CHARGIFY_RELATIONSHIP: '/tier-chargify-product/:id/remove-tier',
}

export const LIST_PAGES = [
  '/programs',
  '/releases',
  '/workouts',
  '/presenters',
  '/music-licensing',
  '/moves',
  '/equipments',
  '/products',
  '/plans',
  '/collections',
  '/reports',
  '/publishing',
  '/publish-plans',
  '/tiers',
  '/tier-chargify-product',
  '/',
]

export default ROUTER
