export const USER_ROLES = {
  author: 'CMDSAuthoringApp_Author',
  publisher: 'CMDSAuthoringApp_Publisher',
}

export const ROLES = {
  publisher: 'Admin',
  author: 'Content',
  viewer: 'Read Only',
}

export const VALID_USERS = [
  'CMDSAuthoringApp_Viewer',
  'CMDSAuthoringApp_Author',
  'CMDSAuthoringApp_Publisher',
]
