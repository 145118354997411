import { Amplify } from 'aws-amplify'

const config = {
  Auth: {
    mandatorySignIn: false,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET, // (required) -  Amazon S3 bucket name
      region: process.env.REACT_APP_AWS_COGNITO_REGION, // (optional) -  Amazon service region
    },
  },
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    authenticationType: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
  },
}
console.log({ Amplify })
Amplify.configure(config)
