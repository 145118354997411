// @flow
import React from 'react'

type Props = {|
  title: string,
  btnText: string,
  link: string,
  linkFooter: string,
  text: string,
  href: string,
  disabled: boolean,
  onClick: () => void,
  children: React.node,
  error: object,
|}

// Define component RegisterForm
const RegisterForm = ({
  title,
  btnText,
  disabled,
  onClick,
  link,
  linkFooter,
  text,
  href,
  children,
  error,
}: Props) => {
  return (
    <div className='form__register-form'>
      <form className='form-wrapper'>
        <div className='form-header'>
          <h3 className='form-header__text'>Les Mills Metadata Admin</h3>
        </div>
        <div className='form-content'>
          <p className='form-content__title'>{title}</p>
          {children}
          {error && <p className='form__text--error'>{error.message}</p>}
          {link && (
            <a href={href} className='form-content__link register-form__link'>
              {link}
            </a>
          )}
          <button
            className='btn btn-submit btn-success btn-success--large btn-register-form'
            onClick={onClick}
            disabled={disabled}
          >
            {btnText}
          </button>
          <div className='form-footer'>
            {text}
            <a href={href} className='register-form__link link__footer'>
              {linkFooter}
            </a>
          </div>
        </div>
      </form>
    </div>
  )
}

// Defined default Props
RegisterForm.defaultProps = {
  title: '',
  btnText: '',
  link: '',
  disabled: false,
}

export default RegisterForm
