//@flow
import React, { createContext, useReducer } from 'react'
import mainReducer, { initialState } from 'contexts/reducers/Main'

import {
  KEY_LOCAL_STORAGE_MAIN_STATE,
  usePersistedContext,
  usePersistedReducer,
} from 'contexts/persist'

type Props = {|
  children: React.Node,
|}

export const MainContext = createContext()

export const MainProvider = ({ children }: Props) => {
  // create a global store to store the theme state
  const stateStore = usePersistedContext(KEY_LOCAL_STORAGE_MAIN_STATE)
  const stateApp = stateStore ? stateStore : initialState

  // global will be a state manager to manage theme state.
  const value = usePersistedReducer(
    useReducer(mainReducer, stateApp),
    KEY_LOCAL_STORAGE_MAIN_STATE // The localStorage theme key
  )

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}
