import { ICONS } from 'assets/icons/index.js'
import ROUTER from 'constants/Router'

export const NAVIGATION = [
  {
    name: 'Programs',
    icon: ICONS.addProgram,
    route: ROUTER.PROGRAMS,
    key: 'programs',
    hasSubMenu: false,
    group: 'content',
  },
  {
    name: 'Releases',
    icon: '',
    route: ROUTER.RELEASES,
    key: 'releases',
    hasSubMenu: false,
    group: 'content',
  },
  {
    name: 'Workouts',
    icon: ICONS.addWorkout,
    route: ROUTER.WORKOUTS,
    key: 'workouts',
    hasSubMenu: true,
    group: 'content',
  },
  {
    name: 'Presenters',
    icon: ICONS.presenters,
    route: ROUTER.PRESENTERS,
    key: 'presenters',
    hasSubMenu: false,
    group: 'content data',
  },
  {
    name: 'Music licensing',
    icon: ICONS.musicLicensing,
    route: ROUTER.MUSIC_LICENSINGS,
    key: 'music-licensing',
    hasSubMenu: false,
    group: 'content data',
  },
  {
    name: 'Moves',
    icon: ICONS.moves,
    route: ROUTER.MOVES,
    key: 'moves',
    hasSubMenu: false,
    group: 'content data',
  },
  {
    name: 'Equipment',
    icon: ICONS.equipment,
    route: ROUTER.EQUIPMENTS,
    key: 'equipment',
    hasSubMenu: false,
    group: 'content data',
  },
  {
    name: 'Plans',
    icon: '',
    route: ROUTER.PLANS,
    key: 'plans',
    hasSubMenu: false,
    group: 'content',
  },
  {
    name: 'Collections',
    icon: '',
    route: ROUTER.COLLECTIONS,
    key: 'collections',
    hasSubMenu: false,
    group: 'content',
  },
  {
    name: 'Publish CMDS',
    icon: '',
    route: ROUTER.PUBLISHING,
    key: 'publishing',
    hasSubMenu: false,
    group: 'publishing',
  },
  {
    name: 'Publish Plans',
    icon: '',
    route: ROUTER.PUBLISH_PLANS,
    key: 'publish-plans',
    hasSubMenu: false,
    group: 'publishing',
  },
  {
    name: 'Tiers',
    icon: '',
    route: ROUTER.TIERS,
    key: 'tiers',
    hasSubMenu: false,
    group: 'tiering',
  },
  {
    name: 'Chargify Product',
    icon: '',
    route: ROUTER.TIER_CHARGIFY_PRODUCTS,
    key: 'tier-chargify-product',
    hasSubMenu: false,
    group: 'tiering',
  },
]
