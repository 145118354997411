import moment from 'moment'
import _ from 'lodash'
import { ROLES } from 'constants/UserRoles'

// There are 3 options: 'dev', 'test' and 'prod'
const { REACT_APP_AWS_S3_ENV, REACT_APP_AWS_S3_URL } = process.env
const env = REACT_APP_AWS_S3_ENV

/**
 * Check what is s3 env (test, dev, prod)
 */
export const getAwsS3Env = () => {
  return env
}

/**
 * @param {object} file
 * @param {function} callback
 * @param {int} maxwidth
 * @param {int} maxheight
 */
export const validateImage = (file, callback, maxDimension, minDimension) => {
  const img = new Image()
  img.src = window.URL.createObjectURL(file)
  img.onload = function () {
    if (!maxDimension && !minDimension) {
      callback(file)
      return
    } else {
      // Format dimension: widthxheight
      const dimensions = minDimension.split('x')
      if (
        // this.width / this.height !== 16 / 9 ||
        this.width !== parseInt(dimensions[0]) ||
        this.height !== parseInt(dimensions[1])
      ) {
        callback(null)
      } else {
        callback(file)
      }
    }
  }
}

/**
 *
 * @param {Array} arr - array of string/number [1, 2, 3]
 */
export const formalizeArrData = (arr, key = 'value', text = 'label') => {
  return arr.map((item) => ({
    [text]: item.toString().trim(),
    [key]: item.toString().trim(),
  }))
}

/**
 * Check template/variant name is matched to format 'Program'&'#'&'Release Number'
 * @param {string} value
 */
export const validTemplateName = (value) => {
  return value.match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#])[A-Za-z\d#]{1,}$/g
  )
}

/**
 * Check index of item in array
 * @param {object} item
 */
export const checkVariantColumns = (item) => item.value.indexOf('variant') > -1

export const getNestedValue = (obj, keys) => {
  if (keys.indexOf('.') > -1) {
    const args = keys.split('.')
    for (var i = 0; i < args.length; i++) {
      if (obj[args[i]] === null) {
        return ''
      }
      /*eslint no-prototype-builtins: off */
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return null
      }
      obj = obj[args[i]]
    }
    return obj
  } else {
    return obj[keys] !== null ? obj[keys] : ''
  }
}

/**
 *
 * @param {array} data
 * @param {object} sortItem
 */
export const sortData = (data, sortItem) => {
  return sortItem
    ? _.orderBy(
        data,
        [
          (item) => {
            const value = getNestedValue(item, sortItem.key)
            return value && isNaN(value)
              ? value.toString().toLowerCase()
              : value
          },
        ],
        [sortItem.value]
      )
    : data
}

/**
 * Format options for select control
 * @param {array} options
 * @param {string} keyField
 * @param {string} labelField
 * @param {array} source
 */
export const formatOptions = (options, source, keyField, labelField) => {
  if (options.length === 0) {
    return options
  }

  return _.uniqBy(options, keyField).map((item) => ({
    [keyField]: item[keyField],
    [labelField]:
      source.length > 0
        ? source.find((child) => child[keyField] === item[keyField])[labelField]
        : '',
  }))
}

export const combineS3Url = (source, subPath, fileName) => {
  source = source
    ? encodeURIComponent(source.trim().toLowerCase().replace(/\s/g, ''))
    : source
  subPath = subPath
    ? encodeURIComponent(subPath.trim().toLowerCase().replace(/\s/g, ''))
    : subPath
  const filePath = (
    subPath ? `${source}/${subPath}/${fileName}` : `${source}/${fileName}`
  ).replace(/\s/g, '')
  return `${REACT_APP_AWS_S3_URL}/images/${filePath}`
}

export const combineBucketUrl = (source, subPath, fileName) => {
  /**
   * 'dev" bucket storage: s3://<bucket_name>/public/programs/imageWide.jpg
   * "test" bucket storage:
   *    - Program: s3://<bucket_name>/images/$programName/imageWide.jpg
   *    - Workout: s3://<bucket_name>/images/$programName/$workoutName/imageWide.jpg
   * "prod" bucket storage: ...
   */

  source = source ? source.trim().toLowerCase().replace(/\s/g, '') : source
  subPath = subPath ? subPath.trim().toLowerCase().replace(/\s/g, '') : subPath

  return (
    subPath ? `${source}/${subPath}/${fileName}` : `${source}/${fileName}`
  ).replace(/\s/g, '')
}

/**
 * Format date
 * @param {string} date
 */
export const formatDate = (date) => {
  return date ? moment.utc(new Date(parseInt(date))).format('DD/MM/YYYY') : ''
}

/**
 * Filter data base on field and keyword
 * @param {array} arr
 * @param {array} dateFields
 * @param {string} field
 * @param {string} keyword
 */
export const filterData = (arr, dateFields, field, keyword) =>
  arr.filter((item) => {
    return dateFields.indexOf(field) > -1
      ? formatDate(item[field]).indexOf(keyword) > -1
      : getNestedValue(item, field)
          .toString()
          .toLowerCase()
          .indexOf(keyword.toLowerCase()) > -1
  })

/**
 * Generate file name by unique timestamp
 * @param {Object} file
 */
export const generateFileName = (file) => {
  const extension = file.name.split('.')[1]
  return `${Math.round(new Date().getTime() / 1000)}.${extension}`
}

/**
 * Validate program color
 * @param {Array} source is array of program
 * @param {String} color
 */
export const validateColorUtil = (source, color) => {
  const exist = source.find((item) => item.color === color)
  if (exist) {
    return 'EXIT'
  }

  if (!/^#[0-9A-F]{6}$/i.test(color)) {
    return 'INVALID'
  }

  return 'VALID'
}

export const validateNumberField = (value, max) => {
  if (!value && value !== '0') {
    return 'Required field'
  }

  return isNaN(value)
    ? 'Must be number'
    : !!max && (Number(value) > max || Number(value) < 0)
    ? `Must be in range 0-${max}`
    : ''
}

/**
 * Validate number include the dot
 * @param {number} value is a number
 */
export const validateIntNumber = (value) => {
  if (!value) {
    return 'Required field'
  }

  return parseInt(value) === 0 || value.indexOf('.') > -1
    ? 'Must be an integer number'
    : ''
}

/**
 * Validate number include the dot
 * @param {number} value is a number
 */
export const validateIntNumberV2 = (value, max, min) => {
  if (!value) {
    return 'Required field'
  }

  var isInteger = /^(0|[1-9]\d*)$/.test(value)
  if (!isInteger) {
    return 'Must be an integer number'
  } else if ((max && Number(value) > max) || (min && Number(value) < min)) {
    return `Must be in range ${min ? min : 0}-${max}`
  } else {
    return ''
  }
}

/**
 * Validate number include the dot
 * @param {number} value is a number
 */
export const validateIntNumberV3 = (value, max, min) => {
  if (value) {
    var isInteger = /^(0|[1-9]\d*)$/.test(value)
    if (!isInteger) {
      return 'Must be an integer number'
    } else if ((max && Number(value) > max) || (min && Number(value) < min)) {
      return `Must be in range ${min ? min : 0}-${max}`
    } else {
      return ''
    }
  }
  return ''
}

export const generateUniqId = () => {
  return new Date().valueOf()
}

/**
 * String with special characters
"^", "$", "", ".", "*", "+", "?", "(", ")", "[", "]", "{", "}", and "|"
will be added `\` behind
 * @param {string} str
 */
export const escape = (str) => {
  if (!str) {
    return ''
  }

  // return _.escapeRegExp(str).replace(/([\/])/gm, '\\$1')
  return JSON.stringify(str).replace(/((^")|("$))/g, '')
}

/**
 * Get nested property
 * @param {*} p: Array of nested properties
 * @param {*} o: Root object
 */
export const getNestedProp = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)

/**
 * Get value
 * @param {object} change
 * @param {object} objDetail
 */
export const getValue = (change, objDetail, key) => {
  const mappedKey = {
    programName: 'name',
    planName: 'name',
    workoutName: 'name',
    name: 'name',
    releaseNumber: 'number',
    releaseName: 'name',
  }

  const tmp =
    _.has(change, key) && !!change[key]
      ? change[key]
      : !_.isEmpty(objDetail)
      ? objDetail[mappedKey[key]]
      : ''

  return tmp
}

export const checkDuplicateValues = (arr, key) => {
  _.forEach(arr, (i) => {
    if (arr.filter((c) => c[key] === i.key).length > 1) {
      return true
    }
  })

  return false
}

/**
 * Validate account
 * @param {string} value
 * @param {boolean} isEmail
 */
export const validateAccount = (value, isEmail) => {
  let errMessage = ''
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!value) {
    errMessage = 'Required field'
  } else if (isEmail && !regexEmail.test(value)) {
    errMessage = 'Email is invalid'
  }
  return errMessage
}

/**
 * Remove all undefined, null value out of array
 * @param {Array} arr
 */
export const compactArray = (arr) => {
  return arr.filter((i) => [undefined, null].indexOf(i) === -1)
}

/**
 * Calculate total page
 * @param {Number} total - Total all items
 * @param {Number} limit - Number of items in one response
 */
export const totalPage = (total, limit) => {
  const result = Math.ceil(total / limit)
  return result === 0 ? 1 : result
}

/**
 * Calculate offset page
 * @param {Number} currentPage
 * @param {Number} limit
 */
export const offsetPage = (currentPage, limit) => {
  // Limit: 50, currentPage: 0 -> 0
  // Limit: 50, currentPage: 1 -> 50
  // Limit: 50, currentPage: 2 -> 100
  return limit * currentPage
}

/**
 * Calculate current page
 * @param {Number} offset
 * @param {Number} limit
 */
export const currentPage = (offset, limit) => {
  return offset / limit
}

export const userRole = (role = []) => {
  if (role.length === 0) {
    return ''
  } else {
    if (role.find((item) => item.toLowerCase().indexOf('_publisher') > -1)) {
      return ROLES.publisher
    } else if (
      role.find((item) => item.toLowerCase().indexOf('_author') > -1)
    ) {
      return ROLES.author
    } else {
      return ROLES.viewer
    }
  }
}

/**
 * Calculate duration into weeks
 * @param {Number} duration - the unit is day
 */
export const calDuration = (duration) => {
  return !duration ? 0 : Math.ceil(duration / 7)
}

export const isEqualLatestDate = (d1, d2) => {
  if (!d1 || !d2) {
    return false
  }

  return d1 === d2
}

export const invalidImgErr = (dimension) => {
  return dimension
    ? `File format or size (${dimension}) incorrect`
    : 'File format or size incorrect'
}

/**
 * Create plain text file from div content
 * @param String contentId
 * @param String linkId
 */
export const createPlainText = (contentId, linkId, name) => {
  const dataWrapper = document.getElementById(contentId)
  const downloadLink = document.getElementById(linkId)

  downloadLink.setAttribute('download', `${name}.text`)
  downloadLink.setAttribute(
    'href',
    window.URL.createObjectURL(
      new Blob(
        [
          dataWrapper.innerHTML
            .replace(/<\/div>/g, '</div>\n')
            .replace(/<\/li>/g, '</li>\n')
            .replace(/<\/p>/g, '</p>\n')
            .replace(/<[^>]*>/g, '')
            .replace(/Download/g, ''),
        ],
        { type: 'text/plain' }
      )
    )
  )
  downloadLink.click()
}

export const selectAndCopy = (contentId) => {
  try {
    const listener = (e) => {
      e.clipboardData.setData(
        'text/plain',
        document
          .getElementById(contentId)
          .innerHTML.replace(/<\/div>/g, '</div>\n')
          .replace(/<\/li>/g, '</li>\n')
          .replace(/<\/p>/g, '</p>\n')
          .replace(/<[^>]*>/g, '')
          .replace(/Download/g, '')
      )
      e.preventDefault()
    }
    document.addEventListener('copy', listener)
    const success = document.execCommand('copy')
    document.removeEventListener('copy', listener)
    return success
  } catch (err) {
    return false
  }
}

export const getProgramIconDimension = (dimension, name) => {
  if (!name) {
    return ''
  }

  const key = Object.keys(dimension).find(
    (i) => i.toLowerCase().indexOf(name.toLowerCase().trim()) > -1
  )
  return key ? dimension[key].min : ''
}

/**
 * Filter data in array by value
 * @param {*} arr [{id: 1, name: 'Person 1'}, {id: 2, name: 'Person 2'}]
 * @param {*} key
 * @param {*} value e.g Person 1, Person 2
 */
export const filterArrByValue = (arr, key, value) => {
  return arr.filter(
    (e) =>
      value &&
      value
        .split(',')
        .map((i) => i.toLowerCase().trim())
        .indexOf(e[key].toLowerCase()) > -1
  )
}

/**
 * Restrict special characters when input text value
 * @param {String} stringValue - Input value need to validate
 * @returns Object | null - Return object error if has error else return null
 */
export const restrictSpecialCharacters = (stringValue) => {
  const isValid = !/\t/g.test(stringValue)
  if (!isValid) {
    // return error message
    return {
      message: 'Must not include syntax: tab',
    }
  }
  return null
}

/**
 * Filter data in array by value with allow non-exist data
 * @param {*} arr [{id: 1, name: 'Person 1'}, {id: 2, name: 'Person 2'}]
 * @param {*} key
 * @param {*} value e.g Person 1, Person 2
 */
export const filterAndAppendNewItemToArr = (arr, key, value) => {
  var allLookups = []
  if (!value) {
    return allLookups
  }

  var arrOfValue = value.split(',')
  let templateItem = arr[0] || {}

  for (let item of arrOfValue) {
    if (!item) {
      continue
    }
    let items = arr.filter(
      (e) => item && item.toLowerCase().trim() === e[key].toLowerCase()
    )

    if (items && items.length > 0) {
      allLookups = allLookups.concat(items)
    } else {
      var newItem = JSON.parse(JSON.stringify(templateItem))
      newItem.id = -1
      newItem[key] = item.trim()
      allLookups.push(newItem)
    }
  }
  return allLookups
}

export const validateUrl = (url) => {
  try {
    new URL(url)
  } catch (e) {
    return 'Format url is invalid'
  }
  return ''
}

/**
 * Check if the menu item is active of inactive
 * @param {String} currentPath - The current path is active
 * @param {String} navKey - The key of navigation menu item
 * @returns {Boolean} - Return status of menu is active or inactive
 */
export const checkMenuIsActive = (currentPath, navKey) => {
  const pathsArray = currentPath.split('/')
  const activePathName = pathsArray[1]
  const activePathNameS = activePathName + 's'
  const isActive =
    activePathName === navKey || activePathNameS === navKey ? true : false

  return isActive
}
