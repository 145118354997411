import {
  getCurrentUserCognito,
  signOutCognito,
  signInCognito,
} from 'services/auth'

export const USER_TYPES = {
  CHANGE_USER: 'CHANGE_USER',
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILED: 'SIGN_IN_FAILED',
  SWITCH_ACC: 'SWITCH_ACC',
  SWITCH_ACC_SUCCESS: 'SWITCH_ACC_SUCCESS',
  SWITCH_ACC_FAILED: 'SWITCH_ACC_FAILED',
  IN_PROCESS: 'IN_PROCESS',
  GET_CURRENT_SUCCESS: 'GET_CURRENT_SUCCESS',
  GET_CURRENT_FAILED: 'GET_CURRENT_FAILED',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILED: 'SIGN_OUT_FAILED',
  RESET_ERROR: 'RESET_ERROR',
  RESET_TYPE: 'RESET_USER_TYPE',
}

export const resetError = () => {
  return {
    type: USER_TYPES.RESET_ERROR,
  }
}

export const changeUser = (user) => {
  return {
    type: USER_TYPES.CHANGE_USER,
    user,
  }
}

export const signIn = (user, dispatch) => {
  signInCognito(user, dispatch)
  return {
    type: USER_TYPES.SIGN_IN,
  }
}

export const getCurrentUser = (dispatch) => {
  getCurrentUserCognito(dispatch)
  return {
    type: USER_TYPES.IN_PROCESS,
  }
}

export const signOut = (dispatch) => {
  signOutCognito(dispatch)
  return {
    type: USER_TYPES.IN_PROCESS,
  }
}

export const getCurrentUserSuccess = (user) => {
  return {
    type: USER_TYPES.GET_CURRENT_SUCCESS,
    user,
  }
}

export const getCurrentUserFailed = () => {
  return {
    type: USER_TYPES.GET_CURRENT_FAILED,
  }
}

export const signInSuccess = (user) => {
  return {
    type: USER_TYPES.SIGN_IN_SUCCESS,
    user,
  }
}

export const signInFailed = (error) => {
  return {
    type: USER_TYPES.SIGN_IN_FAILED,
    error,
  }
}

export const signOutSuccess = () => {
  return {
    type: USER_TYPES.SIGN_OUT_SUCCESS,
  }
}

export const signOutFailed = (error) => {
  return {
    type: USER_TYPES.SIGN_OUT_FAILED,
    error,
  }
}

export const resetType = () => {
  return {
    type: USER_TYPES.RESET_TYPE,
  }
}
