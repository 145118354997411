//@flow
import React, { useState, useCallback } from 'react'
import { graphql, compose } from 'react-apollo'
import _ from 'lodash'

import BaseLayout from 'layouts/base'
import Aside from './components/Aside'

import Indicator from 'components/indicator'

// Queries, actions
import { getListProducts } from 'contexts/actions/Main'
import { queryProducts } from 'graphql/product/Queries'

import { connects } from 'contexts/helpers/Connect'
import { UserContext } from 'contexts/providers/User'
import { MainContext } from 'contexts/providers/Main'

import { expandedWidth, collapsedWidth } from 'constants/Common'

type Props = {|
  children?: React.Node,
  onSignOut?: () => {},
  resetData?: () => {},
  user?: Object,
  productsLoading?: boolean,
  isLoading: boolean,
  isPrivate?: boolean,
  type: string,
  isPrivate: isShowPopup,
  asideWidth: Int,
  handleToggleAside: Function,
|}

export const internalHook = () => {
  const [asideWidth, toggleAside] = useState(expandedWidth)

  const handleToggleAside = useCallback(() => {
    toggleAside(asideWidth > collapsedWidth ? collapsedWidth : expandedWidth)
  })

  return {
    asideWidth,
    handleToggleAside,
  }
}

const DashboardLayout = ({
  children,
  isPrivate,
  productsLoading,
  asideWidth,
  handleToggleAside,
}: Props) => {
  return (
    <BaseLayout isPrivate={isPrivate}>
      {isPrivate && (
        <>
          <div className='App-sidebar' style={{ width: `${asideWidth}px` }}>
            <Aside
              productsLoading={productsLoading}
              asideWidth={asideWidth}
              onToggleAside={handleToggleAside}
            />
          </div>
          {productsLoading ? (
            <Indicator />
          ) : (
            <div
              className='App-content'
              style={{
                width: `calc(100% - ${asideWidth}px)`,
                marginLeft: `calc(${asideWidth}px)`,
              }}
            >
              {children}
            </div>
          )}
        </>
      )}
      {!isPrivate && <>{children}</>}
    </BaseLayout>
  )
}

const mapStateToProps = (state = []) => {
  const [userState, mainState] = state
  return {
    user: userState.user,
    products: mainState.products ? mainState.products.list : [],
  }
}

const mapDispatchToProps = (dispatch = []) => {
  const [, mainDispatch] = dispatch
  return {
    getListProducts: (data) => mainDispatch(getListProducts(data)),
  }
}

const DashboardLayoutWrapper = (props) => (
  <DashboardLayout {...internalHook()} {...props} />
)

const DashboardLayoutContainer = compose(
  graphql(queryProducts, {
    options: (props) => ({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const result = _.orderBy(data.listProducts, ['name'], ['asc'])
        !_.isEqual(result, props.products) && props.getListProducts(result)
      },
    }),
    skip: (props) =>
      _.isEmpty(props.user) ||
      !props.user.accessToken ||
      props.products.length > 0,
    props: ({ data }) => {
      return {
        productsLoading: data.loading,
        productsError: data.error,
      }
    },
  })
)(DashboardLayoutWrapper)

export default connects(
  [UserContext, MainContext],
  mapStateToProps,
  mapDispatchToProps
)(DashboardLayoutContainer)
