// @flow
import React, { useState, useEffect } from 'react'
import Select, { components as defaultComponents } from 'react-select'
import customMenuList from './CustomMenuList'
import customMultiValueLabel from './CustomMultiValueLabel'
import M from 'materialize-css/dist/js/materialize.min.js'

type Props = {|
  defaultPlaceholder?: string,
  defaultValue?: string,
  title: string,
  name?: string,
  key: string,
  className: string,
  menuPosition: string,
  isMulti: boolean,
  isDisabled: boolean,
  menuIsOpen: boolean,
  isfetchMore: boolean,
  isShowToolTip: boolean,
  allowAdd: boolean,
  error?: object,
  options: array,
  selectRef: () => void,
  getOptionLabel: () => void,
  getOptionValue: () => void,
  handleFocus: () => void,
  onChange: () => void,
  handleBlur: () => void,
  onMenuClose: () => void,
  onMenuOpen: () => void,
  onMenuScrollToBottom: () => void,
  loadOptions: () => void,
  onKeyDown: () => void,
  onCreate: () => void,
  noOptionsMessage: String,
|}

export const internalHook = ({ placeholder, onFocus, isShowToolTip }) => {
  const [defaultPlaceholder, updatePlaceholder] = useState(placeholder)

  useEffect(() => {
    if (isShowToolTip) {
      var elems = document.querySelectorAll('.tooltipped')
      M.Tooltip.init(elems, { enterDelay: 100, inDuration: 200 })
    }
  })

  const handleFocus = (event) => {
    updatePlaceholder('')
    onFocus && onFocus(event)
  }

  const handleBlur = () => {
    updatePlaceholder(placeholder)
  }

  return {
    defaultPlaceholder,
    handleFocus,
    handleBlur,
  }
}

export const SelectComponent = (props: Props) => {
  const {
    className,
    defaultPlaceholder,
    name,
    defaultValue,
    key,
    title,
    isDisabled,

    error,
    isMulti,
    options,
    menuIsOpen,

    handleFocus,
    selectRef,
    onChange,
    getOptionLabel,
    getOptionValue,
    handleBlur,
    menuPosition,
    loadOptions,
    isfetchMore,
    onKeyDown,
    isShowToolTip,
    allowAdd,
    onCreate,
    noOptionsMessage,
    onMenuOpen,
    onMenuClose,
    isLoading,
  } = props

  const isError = error && error[name]

  const extendProps = isfetchMore
    ? {
        filterOption: () => {
          return []
        },
      }
    : {}

  return (
    <Select
      className={`${className ? className : 'basic-multi-select'} ${
        isError ? 'basic-multi-select--error' : ''
      }`}
      defaultValue={defaultValue}
      isMulti={isMulti}
      name={name}
      options={options}
      classNamePrefix='select'
      placeholder={defaultPlaceholder}
      onFocus={handleFocus}
      onChange={onChange}
      ref={selectRef}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      key={key}
      title={title}
      onBlur={handleBlur}
      isDisabled={isDisabled}
      menuIsOpen={menuIsOpen}
      menuPosition={menuPosition}
      handleScrolledToBottom={loadOptions}
      components={{
        MenuList: customMenuList(
          defaultComponents.MenuList,
          allowAdd,
          onCreate,
          isLoading
        ),
        MultiValueLabel: customMultiValueLabel(
          defaultComponents.MultiValueLabel,
          isShowToolTip
        ),
        LoadingIndicator: null,
      }}
      isfetchMore={isfetchMore}
      onKeyDown={onKeyDown}
      {...extendProps}
      noOptionsMessage={() => noOptionsMessage}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  )
}

const SelectWrapper = (props) => (
  <SelectComponent
    {...internalHook({
      placeholder: props.placeholder,
      onFocus: props.onFocus,
      isShowToolTip: props.isShowToolTip,
    })}
    {...props}
  />
)

// Defined default Props
SelectWrapper.defaultProps = {
  isError: false,
  type: 'text',
  maxlength: 500,
  noOptionsMessage: 'Not found',
  selectRef: () => {},
  isfetchMore: false,
  isShowToolTip: false,
  allowAdd: false,
}

export default SelectWrapper
