// @flow
import React, { useRef, useEffect, useState } from 'react'
import _ from 'lodash'

// Component
import RegisterForm from 'components/registerForm'
import FormRow from 'components/formRow'
import Indicator from 'components/indicator'

// Helper, constants
import { validateAccount } from 'helpers/Utilities'
import { signIn } from 'contexts/actions/User'
import { connects } from 'contexts/helpers/Connect'
import { UserContext } from 'contexts/providers/User'
import { USER_TYPES, resetError, resetType } from 'contexts/actions/User'
import ROUTER from 'constants/Router'

type Props = {|
  type: string,
  emailRef: () => void,
  passwordRef: () => void,
  handleChange: () => void,
  onSignIn: () => void,
  handleSignIn: () => void,
  togglePassword: () => void,
  isPrivate: boolean,
  isLoading: boolean,
  isShowPassword: boolean,
  isSignOut: boolean,
  error: object,
  account: object,
  internalErr: object,
|}

/** Sign in controls props values */
const SignInFormControl = (
  internalErr,
  error,
  account,
  emailRef,
  passwordRef,
  handleChange,
  isShowPassword,
  togglePassword
) => {
  return [
    {
      title: 'Email',
      type: 'textbox',
      data: {
        placeholder: 'Email',
        defaultValue: account ? account.username : '',
        ref: emailRef,
        onChange: handleChange,
        name: 'email',
        type: 'email',
        maxlength: 50,
        subTitle: 'Email',
        className: `form__textbox ${
          !_.isEmpty(error) && _.isEmpty(internalErr)
            ? 'form__textbox--error'
            : ''
        }`,
      },
    },
    {
      title: 'Password',
      type: 'textbox',
      data: {
        isShowPassword: isShowPassword,
        showIcon: true,
        onClick: togglePassword,
        placeholder: 'Password',
        autoComplete: 'off',
        defaultValue: account ? account.password : '',
        ref: passwordRef,
        onChange: handleChange,
        type: isShowPassword ? 'text' : 'password',
        name: 'password',
        maxlength: 50,
        subTitle: 'Password',
        className: `form__textbox ${
          !_.isEmpty(error) && _.isEmpty(internalErr)
            ? 'form__textbox--error'
            : ''
        }`,
      },
    },
  ]
}

export const internalHook = ({
  onSignIn,
  userDispatch,
  type,
  history,
  resetError,
  resetType,
}) => {
  const [internalErr, updateInternalErr] = useState({})
  const [isShowPassword, updatePassword] = useState(false)
  const [account, updateAccount] = useState('')
  const emailRef = useRef('')
  const passwordRef = useRef('')

  useEffect(() => {
    resetError()
  }, [])

  useEffect(() => {
    if (type === USER_TYPES.SIGN_IN_SUCCESS) {
      history.push(ROUTER.HOME)
    } else if (type === USER_TYPES.SIGN_OUT_SUCCESS) {
      setTimeout(() => {
        resetType()
      }, 1000)
    }
  }, [type])

  /**
   * handle login AWS cognito
   */
  const handleSignIn = (event) => {
    event && event.preventDefault()
    const username = emailRef.current.value
    const password = passwordRef.current.value
    const user = {
      username,
      password,
    }

    updateAccount(user)

    const validate = {
      email: validateAccount(username, true),
      password: validateAccount(password),
    }

    // Validate for required fields: email, password
    if (Object.values(validate).find((item) => !!item)) {
      updateInternalErr({
        ...validate,
      })
      return
    } else {
      updateInternalErr({})
      onSignIn(user, userDispatch)
    }
  }

  const togglePassword = () => {
    updatePassword(!isShowPassword)
  }

  return {
    handleSignIn,
    emailRef,
    passwordRef,
    internalErr,
    account,
    isShowPassword,
    togglePassword,
  }
}

export const SignIn = ({
  internalErr,
  handleSignIn,
  handleChange,
  emailRef,
  passwordRef,
  isLoading,
  error,
  account,
  isShowPassword,
  togglePassword,
  type,
}: Props) => {
  if (isLoading) {
    return <Indicator />
  }

  return (
    <div className='container'>
      {type === USER_TYPES.SIGN_OUT_SUCCESS && (
        <p className='form__signout-successfull'>
          You have successfully signed out
        </p>
      )}
      <RegisterForm
        title='Sign in with your email and password'
        btnText='Sign in'
        link='Forgot your password?'
        text='Need an account?'
        linkFooter='Sign up'
        href='/sign-in'
        onClick={handleSignIn}
        error={_.isEmpty(internalErr) && error}
      >
        {SignInFormControl(
          internalErr,
          error,
          account,
          emailRef,
          passwordRef,
          handleChange,
          isShowPassword,
          togglePassword
        ).map((item, index) => (
          <FormRow
            key={index}
            title={item.title}
            data={item.data}
            type={item.type}
            error={internalErr}
          />
        ))}
      </RegisterForm>
    </div>
  )
}

const SignInWrapper = (props) => <SignIn {...internalHook(props)} {...props} />

const mapStateToProps = (state = []) => {
  const [userState] = state
  return {
    error: userState.inProcess.error,
    isLoading: userState.inProcess.isLoading,
    type: userState.type,
  }
}

const mapDispatchToProps = (dispatch = []) => {
  const [userDispatch] = dispatch
  return {
    onSignIn: (user) => userDispatch(signIn(user, userDispatch)),
    userDispatch,
    resetError: () => userDispatch(resetError()),
    resetType: () => userDispatch(resetType()),
  }
}

export default connects(
  [UserContext],
  mapStateToProps,
  mapDispatchToProps
)(SignInWrapper)
