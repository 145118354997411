export const MAIN_TYPES = {
  GET_LIST_PROGRAMS: 'GET_LIST_PROGRAMS',
  GET_LIST_PRODUCTS: 'GET_LIST_PRODUCTS',
  GET_LIST_RELEASES: 'GET_LIST_RELEASES',
  GET_LIST_COUNTRIES: 'GET_LIST_COUNTRIES',
  GET_LIST_DIRECTORS: 'GET_LIST_DIRECTORS',
  GET_LIST_MUSCLES: 'GET_LIST_MUSCLES',
  CREATE_PROGRAM_SUCCESS: 'CREATE_PROGRAM_SUCCESS',
  RESET_TYPE: 'RESET_TYPE',
  ON_ERROR: 'ON_ERROR',
  RESET_DATA: 'RESET_DATA',

  // PROGRAM
  PROCESSING_API: 'PROCESSING_API',
  PROCESSING_API_END: 'PROCESSING_API_END',
  GET_PROGRAM_DETAIL: 'GET_PROGRAM_DETAIL',
  SAVE_PROGRAM_MASTER_CLASS_TEMPLATE: 'SAVE_PROGRAM_MASTER_CLASS_TEMPLATE',
  SAVE_PROGRAM_MASTER_CLASS_TRACKS: 'SAVE_PROGRAM_MASTER_CLASS_TRACKS',
  SAVE_PROGRAM_MASTER_CLASS_VARIANTS: 'SAVE_PROGRAM_MASTER_CLASS_VARIANTS',
  SAVE_PROGRAM_REFETCH_FLAG: 'SAVE_PROGRAM_REFETCH_FLAG',
  UPDATE_SEARCH_PROGRAM_FIELDS: 'UPDATE_SEARCH_PROGRAM_FIELDS',

  // RELEASE
  GET_RELEASE_DETAIL: 'GET_RELEASE_DETAIL',
  PROCESSING_RELEASE_API_END: 'PROCESSING_RELEASE_API_END',
  PROCESSING_RELEASE_API: 'PROCESSING_RELEASE_API',
  RESET_RELEASE_TYPE: 'RESET_RELEASE_TYPE',
  CREATE_RELEASE_SUCCESS: 'CREATE_RELEASE_SUCCESS',
  CREATE_MUSIC_SUCCESS: 'CREATE_MUSIC_SUCCESS',
  UPDATE_MUSIC_SUCCESS: 'UPDATE_MUSIC_SUCCESS',
  CREATE_RELEASE_TRACK_SUCCESS: 'CREATE_RELEASE_TRACK_SUCCESS',
  UPDATE_RELEASE_TRACK_SUCCESS: 'UPDATE_RELEASE_TRACK_SUCCESS',
  SAVE_RELEASE_REFETCH_FLAG: 'SAVE_RELEASE_REFETCH_FLAG',
  SAVE_RELEASE_LIST_META: 'SAVE_RELEASE_LIST_META',
  RESET_RELEASE_META: 'RESET_RELEASE_META',
  UPDATE_SORT_RELEASE_FIELDS: 'UPDATE_SORT_RELEASE_FIELDS',
  UPDATE_SEARCH_RELEASE_FIELDS: 'UPDATE_SEARCH_RELEASE_FIELDS',

  // MUSIC
  STORE_LIST_MUSICS: 'STORE_LIST_MUSICS',

  // WORKOUT
  GET_LIST_WORKOUTS: 'GET_LIST_WORKOUTS',
  GET_WORKOUT_DETAIL: 'GET_WORKOUT_DETAIL',
  CREATE_WORKOUT_SUCCESS: 'CREATE_WORKOUT_SUCCESS',
  PROCESSING_WORKOUT_API: 'PROCESSING_WORKOUT_API',
  PROCESSING_WORKOUT_API_END: 'PROCESSING_WORKOUT_API_END',
  RESET_WORKOUT_TYPE: 'RESET_WORKOUT_TYPE',
  SAVE_WORKOUT_MUSICS: 'SAVE_WORKOUT_MUSICS',
  SAVE_WORKOUT_TRACKS: 'SAVE_WORKOUT_TRACKS',
  UPDATE_WORKOUT_SUCCESS: 'UPDATE_WORKOUT_SUCCESS',
  UPDATE_WORKOUT_MUSICS_SUCCESS: 'UPDATE_WORKOUT_MUSICS_SUCCESS',
  UPDATE_WORKOUT_TRACKS_SUCCESS: 'UPDATE_WORKOUT_TRACKS_SUCCESS',
  SAVE_WORKOUT_REFETCH_FLAG: 'SAVE_WORKOUT_REFETCH_FLAG',
  SAVE_WORKOUT_LIST_META: 'SAVE_WORKOUT_LIST_META',
  RESET_WORKOUT_META: 'RESET_WORKOUT_META',
  SAVE_WORKOUT_STUFFS: 'SAVE_WORKOUT_STUFFS',
  UPDATE_SORT_WORKOUT_FIELDS: 'UPDATE_SORT_WORKOUT_FIELDS',
  UPDATE_SEARCH_WORKOUT_FIELDS: 'UPDATE_SEARCH_WORKOUT_FIELDS',
  SAVE_WORKOUT_VIMEO_URL: 'SAVE_WORKOUT_VIMEO_URL',

  // MOVE
  RESET_MOVE_TYPE: 'RESET_MOVE_TYPE',
  CREATE_MOVE_SUCCESS: 'CREATE_MOVE_SUCCESS',
  CREATE_MOVE_REQUEST: 'CREATE_MOVE_REQUEST',
  GET_MOVE_DETAIL: 'GET_MOVE_DETAIL',
  GET_LIST_MOVES: 'GET_LIST_MOVES',
  PROCESSING_MOVE_API: 'PROCESSING_MOVE_API',
  PROCESSING_MOVE_API_END: 'PROCESSING_MOVE_API_END',
  SAVE_MOVE_LIST_META: 'SAVE_MOVE_LIST_META',
  RESET_MOVE_META: 'RESET_MOVE_META',
  UPDATE_SORT_MOVE_FIELDS: 'UPDATE_SORT_MOVE_FIELDS',
  UPDATE_SEARCH_MOVE_FIELDS: 'UPDATE_SEARCH_MOVE_FIELDS',
  SAVE_MOVE_REFETCH_FLAG: 'SAVE_MOVE_REFETCH_FLAG',

  // EQUIPMENT
  RESET_EQUIPMENT_TYPE: 'RESET_EQUIPMENT_TYPE',
  CREATE_EQUIPMENT_SUCCESS: 'CREATE_EQUIPMENT_SUCCESS',
  GET_EQUIPMENT_DETAIL: 'GET_EQUIPMENT_DETAIL',
  GET_LIST_EQUIPMENTS: 'GET_LIST_EQUIPMENTS',
  PROCESSING_EQUIPMENT_API: 'PROCESSING_EQUIPMENT_API',
  PROCESSING_EQUIPMENT_API_END: 'PROCESSING_EQUIPMENT_API_END',

  // MUSIC LABEL
  RESET_MUSIC_LABEL_TYPE: 'RESET_MUSIC_LABEL_TYPE',
  CREATE_MUSIC_LABEL_SUCCESS: 'CREATE_MUSIC_LABEL_SUCCESS',
  GET_MUSIC_LABEL_DETAIL: 'GET_MUSIC_LABEL_DETAIL',
  GET_LIST_MUSIC_LABELS: 'GET_LIST_MUSIC_LABELS',
  PROCESSING_MUSIC_LABEL_API: 'PROCESSING_MUSIC_LABEL_API',
  PROCESSING_MUSIC_LABEL_API_END: 'PROCESSING_MUSIC_LABEL_API_END',

  // PRESENTER
  RESET_PRESENTER_TYPE: 'RESET_PRESENTER_TYPE',
  CREATE_PRESENTER_SUCCESS: 'CREATE_PRESENTER_SUCCESS',
  GET_PRESENTER_DETAIL: 'GET_PRESENTER_DETAIL',
  GET_LIST_PRESENTERS: 'GET_LIST_PRESENTERS',
  PROCESSING_PRESENTER_API: 'PROCESSING_PRESENTER_API',
  PROCESSING_PRESENTER_API_END: 'PROCESSING_PRESENTER_API_END',

  // PLAN
  GET_LIST_PLANS: 'GET_LIST_PLANS',
  GET_PLAN_DETAIL: 'GET_PLAN_DETAIL',
  RESET_PLAN_TYPE: 'RESET_PLAN_TYPE',
  CREATE_PLAN_SUCCESS: 'CREATE_PLAN_SUCCESS',
  PROCESSING_PLAN_API: 'PROCESSING_PLAN_API',
  PROCESSING_PLAN_API_END: 'PROCESSING_PLAN_API_END',
  SAVE_SCHEDULE_SUCCESS: 'SAVE_SCHEDULE_SUCCESS',
  SAVE_PLAN_REFETCH_FLAG: 'SAVE_PLAN_REFETCH_FLAG',
  UPDATE_SEARCH_PLAN_FIELDS: 'UPDATE_SEARCH_PLAN_FIELDS',

  // COLLECTION
  GET_LIST_COLLECTIONS: 'GET_LIST_COLLECTIONS',
  UPDATE_SEARCH_COLLECTION_FIELDS: 'UPDATE_SEARCH_COLLECTION_FIELDS',
  GET_COLLECTION_DETAIL: 'GET_COLLECTION_DETAIL',
  SAVE_COLLECTION_REFETCH_FLAG: 'SAVE_COLLECTION_REFETCH_FLAG',
  RESET_COLLECTION_TYPE: 'RESET_COLLECTION_TYPE',
  GET_COLLECTION_TYPES: 'GET_COLLECTION_TYPES',
  PROCESSING_COLLECTION_API: 'PROCESSING_COLLECTION_API',
  PROCESSING_COLLECTION_API_END: 'PROCESSING_COLLECTION_API_END',
  CREATE_COLLECTION_SUCCESS: 'CREATE_COLLECTION_SUCCESS',
  UPDATE_WORKOUTS_COLLECTION_SUCCESS: 'UPDATE_WORKOUTS_COLLECTION_SUCCESS',
  SAVE_COLLECTION_LIST_META: 'SAVE_COLLECTION_LIST_META',
  UPDATE_SORT_COLLECTION_FIELDS: 'UPDATE_SORT_COLLECTION_FIELDS',
  RESET_COLLECTION_META: 'RESET_COLLECTION_META',

  // PUBLISHING
  RESET_PUBLISHING_TYPE: 'RESET_PUBLISHING_TYPE',
  CREATE_PUBLISHING_SUCCESS: 'CREATE_PUBLISHING_SUCCESS',
  GET_PUBLISHING_DETAIL: 'GET_PUBLISHING_DETAIL',
  GET_LIST_PUBLISHING: 'GET_LIST_PUBLISHING',
  PROCESSING_PUBLISHING_API: 'PROCESSING_PUBLISHING_API',
  PROCESSING_PUBLISHING_API_END: 'PROCESSING_PUBLISHING_API_END',
  FINISH_PUBLISH_STEP: 'FINISH_PUBLISH_STEP',
  START_PUBLISH_STEP: 'START_PUBLISH_STEP',
  SAVE_PUBLISHING_REFETCH_FLAG: 'SAVE_PUBLISHING_REFETCH_FLAG',

  // TIERING
  LIST_TIER_CHARGIFY_PRODUCTS: 'LIST_TIER_CHARGIFY_PRODUCTS',
  RESET_TIER_CHARGIFY_PRODUCTS: 'RESET_TIER_CHARGIFY_PRODUCTS',
  RESET_TIERING_CHARGIFY_PRODUCTS: 'RESET_TIERING_CHARGIFY_PRODUCTS',
  LIST_TIERS: 'LIST_TIERS',
  LIST_ALL_TIERS: 'LIST_ALL_TIERS',
  LIST_TIERS_CHARGIFY_RELATIONSHIPS: 'LIST_TIERS_CHARGIFY_RELATIONSHIPS',
  UPDATE_SEARCH_TIER_CHARGIFY_FIELDS: 'UPDATE_SEARCH_TIER_CHARGIFY_FIELDS',
}

export const onError = (error) => {
  return {
    error,
    type: MAIN_TYPES.ON_ERROR,
  }
}

export const resetData = () => {
  return {
    type: MAIN_TYPES.RESET_DATA,
  }
}

export const getListPrograms = (data) => {
  return {
    programs: data,
    type: MAIN_TYPES.GET_LIST_PROGRAMS,
  }
}

export const getListProducts = (data) => {
  return {
    products: data,
    type: MAIN_TYPES.GET_LIST_PRODUCTS,
  }
}

export const getListCountries = (data) => {
  return {
    countries: data,
    type: MAIN_TYPES.GET_LIST_COUNTRIES,
  }
}

export const getListDirectors = (data) => {
  return {
    directors: data,
    type: MAIN_TYPES.GET_LIST_DIRECTORS,
  }
}

export const getListMuscles = (data) => {
  return {
    muscles: data,
    type: MAIN_TYPES.GET_LIST_MUSCLES,
  }
}

export const createProgramSuccess = (data) => {
  return {
    response: data,
    type: MAIN_TYPES.CREATE_PROGRAM_SUCCESS,
  }
}

export const resetMainType = () => {
  return {
    type: MAIN_TYPES.RESET_TYPE,
  }
}

export const processingApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_API,
  }
}

export const processingApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_API_END,
  }
}

export const getProgramDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_PROGRAM_DETAIL,
  }
}

export const saveProgramMasterClassTemplate = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_PROGRAM_MASTER_CLASS_TEMPLATE,
  }
}

export const saveProgramMasterClassTracks = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_PROGRAM_MASTER_CLASS_TRACKS,
  }
}

export const saveProgramMasterClassVariants = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_PROGRAM_MASTER_CLASS_VARIANTS,
  }
}

export const saveProgramRefetchFlag = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_PROGRAM_REFETCH_FLAG,
  }
}

export const updateSearchProgramFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SEARCH_PROGRAM_FIELDS,
  }
}

// RELEASES
export const getListReleases = (data) => {
  return {
    releases: data,
    type: MAIN_TYPES.GET_LIST_RELEASES,
  }
}

export const getReleaseDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_RELEASE_DETAIL,
  }
}

export const processingReleaseApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_RELEASE_API,
  }
}

export const processingReleaseApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_RELEASE_API_END,
  }
}

export const resetReleaseType = () => {
  return {
    type: MAIN_TYPES.RESET_RELEASE_TYPE,
  }
}

export const storeListMusics = () => {
  return {
    type: MAIN_TYPES.STORE_LIST_MUSICS,
  }
}

export const createReleaseSuccess = (data) => {
  return {
    response: data,
    type: MAIN_TYPES.CREATE_RELEASE_SUCCESS,
  }
}

export const createMusicSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_MUSIC_SUCCESS,
  }
}

export const updateMusicSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_MUSIC_SUCCESS,
  }
}

export const createReleaseTrackSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_RELEASE_TRACK_SUCCESS,
  }
}

export const updateReleaseTrackSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_RELEASE_TRACK_SUCCESS,
  }
}

export const saveReleaseRefetchFlag = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_RELEASE_REFETCH_FLAG,
  }
}

export const saveReleaseListMeta = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_RELEASE_LIST_META,
  }
}

export const resetReleaseMeta = () => {
  return {
    type: MAIN_TYPES.RESET_RELEASE_META,
  }
}

export const updateSortReleaseFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SORT_RELEASE_FIELDS,
  }
}

export const updateSearchReleaseFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SEARCH_RELEASE_FIELDS,
  }
}

// WORKOUTS
export const getListWorkouts = (data) => {
  return {
    workouts: data,
    type: MAIN_TYPES.GET_LIST_WORKOUTS,
  }
}

export const getWorkoutDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_WORKOUT_DETAIL,
  }
}

export const createWorkoutSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_WORKOUT_SUCCESS,
  }
}

export const processingWorkoutApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_WORKOUT_API,
  }
}

export const processingWorkoutApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_WORKOUT_API_END,
  }
}

export const resetWorkoutType = () => {
  return {
    type: MAIN_TYPES.RESET_WORKOUT_TYPE,
  }
}

export const saveWorkoutMusics = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_WORKOUT_MUSICS,
  }
}

export const saveWorkoutTracks = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_WORKOUT_TRACKS,
  }
}

export const updateWorkoutSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_WORKOUT_SUCCESS,
  }
}

export const updateWorkoutMusicsSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_WORKOUT_MUSICS_SUCCESS,
  }
}

export const updateWorkoutTracksSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_WORKOUT_TRACKS_SUCCESS,
  }
}

export const saveWorkoutRefetchFlag = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_WORKOUT_REFETCH_FLAG,
  }
}

export const saveWorkoutListMeta = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_WORKOUT_LIST_META,
  }
}

export const resetWorkoutMeta = () => {
  return {
    type: MAIN_TYPES.RESET_WORKOUT_META,
  }
}

export const updateSortWorkoutFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SORT_WORKOUT_FIELDS,
  }
}

export const updateSearchWorkoutFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SEARCH_WORKOUT_FIELDS,
  }
}

export const saveWorkoutVimeoUrl = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_WORKOUT_VIMEO_URL,
  }
}

// Save workout programs, releases
export const saveWorkoutStuffs = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_WORKOUT_STUFFS,
  }
}

// Move
export const resetMoveType = () => {
  return {
    type: MAIN_TYPES.RESET_MOVE_TYPE,
  }
}

export const getMoveDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_MOVE_DETAIL,
  }
}

export const createMoveSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_MOVE_SUCCESS,
  }
}

export const createMoveRequest = () => {
  return {
    data: {},
    type: MAIN_TYPES.CREATE_MOVE_REQUEST,
  }
}

export const getListMoves = (data) => {
  return {
    moves: data,
    type: MAIN_TYPES.GET_LIST_MOVES,
  }
}

export const processingMoveApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_MOVE_API,
  }
}

export const processingMoveApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_MOVE_API_END,
  }
}

export const saveMoveListMeta = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_MOVE_LIST_META,
  }
}

export const resetMoveMeta = () => {
  return {
    type: MAIN_TYPES.RESET_MOVE_META,
  }
}

export const updateSortMoveFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SORT_MOVE_FIELDS,
  }
}

export const updateSearchMoveFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SEARCH_MOVE_FIELDS,
  }
}

export const saveMoveRefetchFlag = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_MOVE_REFETCH_FLAG,
  }
}

// Equipments
export const getListEquipments = (data) => {
  return {
    equipments: data,
    type: MAIN_TYPES.GET_LIST_EQUIPMENTS,
  }
}

export const resetEquipmentType = () => {
  return {
    type: MAIN_TYPES.RESET_EQUIPMENT_TYPE,
  }
}

export const getEquipmentDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_EQUIPMENT_DETAIL,
  }
}

export const createEquipmentSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_EQUIPMENT_SUCCESS,
  }
}

export const processingEquipmentApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_EQUIPMENT_API,
  }
}

export const processingEquipmentApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_EQUIPMENT_API_END,
  }
}

// Music Label
export const resetMusicLabelType = () => {
  return {
    type: MAIN_TYPES.RESET_MUSIC_LABEL_TYPE,
  }
}

export const getMusicLabelDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_MUSIC_LABEL_DETAIL,
  }
}

export const createMusicLabelSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_MUSIC_LABEL_SUCCESS,
  }
}

export const getListMusicLabels = (data) => {
  return {
    musicLabels: data,
    type: MAIN_TYPES.GET_LIST_MUSIC_LABELS,
  }
}

export const processingMusicLabelApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_MUSIC_LABEL_API,
  }
}

export const processingMusicLabelApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_MUSIC_LABEL_API_END,
  }
}

// Presenter
export const resetPresenterType = () => {
  return {
    type: MAIN_TYPES.RESET_PRESENTER_TYPE,
  }
}

export const getPresenterDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_PRESENTER_DETAIL,
  }
}

export const createPresenterSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_PRESENTER_SUCCESS,
  }
}

export const getListPresenters = (data) => {
  return {
    presenters: data,
    type: MAIN_TYPES.GET_LIST_PRESENTERS,
  }
}

export const processingPresenterApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_PRESENTER_API,
  }
}

export const processingPresenterApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_PRESENTER_API_END,
  }
}

// PLAN
export const getListPlans = (data) => {
  return {
    plans: data,
    type: MAIN_TYPES.GET_LIST_PLANS,
  }
}

export const getPlanDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_PLAN_DETAIL,
  }
}

export const resetPlanType = () => {
  return {
    type: MAIN_TYPES.RESET_PLAN_TYPE,
  }
}

export const createPlanSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_PLAN_SUCCESS,
  }
}

export const saveScheduleSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_SCHEDULE_SUCCESS,
  }
}

export const savePlanRefetchFlag = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_PLAN_REFETCH_FLAG,
  }
}

export const processingPlanApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_PLAN_API,
  }
}

export const processingPlanApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_PLAN_API_END,
  }
}

export const updateSearchPlanFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SEARCH_PLAN_FIELDS,
  }
}

// Collection
export const updateSortCollectionFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SORT_COLLECTION_FIELDS,
  }
}

export const saveCollectionListMeta = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_COLLECTION_LIST_META,
  }
}

export const getListCollections = (data) => {
  return {
    collections: data,
    type: MAIN_TYPES.GET_LIST_COLLECTIONS,
  }
}
export const updateSearchCollectionFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SEARCH_COLLECTION_FIELDS,
  }
}

export const getCollectionDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_COLLECTION_DETAIL,
  }
}

export const getCollectionTypes = (data) => {
  return {
    collectionTypes: data,
    type: MAIN_TYPES.GET_COLLECTION_TYPES,
  }
}

export const saveCollectionRefetchFlag = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_COLLECTION_REFETCH_FLAG,
  }
}

export const resetCollectionType = () => {
  return {
    type: MAIN_TYPES.RESET_COLLECTION_TYPE,
  }
}

export const processingCollectionApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_COLLECTION_API,
  }
}

export const processingCollectionApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_COLLECTION_API_END,
  }
}

export const createCollectionSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_COLLECTION_SUCCESS,
  }
}

export const updateWorkoutsCollectionSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_WORKOUTS_COLLECTION_SUCCESS,
  }
}

export const resetCollectionMeta = () => {
  return {
    type: MAIN_TYPES.RESET_COLLECTION_META,
  }
}

// Publishing
export const getListPublishing = (data) => {
  return {
    publishings: data,
    type: MAIN_TYPES.GET_LIST_PUBLISHING,
  }
}

export const resetPublishingType = () => {
  return {
    type: MAIN_TYPES.RESET_PUBLISHING_TYPE,
  }
}

export const getPublishingDetail = (data) => {
  return {
    data,
    type: MAIN_TYPES.GET_PUBLISHING_DETAIL,
  }
}

export const finishPublishStep = (data) => {
  return {
    data,
    type: MAIN_TYPES.FINISH_PUBLISH_STEP,
  }
}

export const startPublishStep = (data) => {
  return {
    data,
    type: MAIN_TYPES.START_PUBLISH_STEP,
  }
}

export const createPublishingSuccess = (data) => {
  return {
    data,
    type: MAIN_TYPES.CREATE_PUBLISHING_SUCCESS,
  }
}

export const processingPublishingApi = () => {
  return {
    type: MAIN_TYPES.PROCESSING_PUBLISHING_API,
  }
}

export const processingPublishingApiEnd = () => {
  return {
    type: MAIN_TYPES.PROCESSING_PUBLISHING_API_END,
  }
}

export const savePublishingRefetchFlag = (data) => {
  return {
    data,
    type: MAIN_TYPES.SAVE_PUBLISHING_REFETCH_FLAG,
  }
}

export const listTierChargifyProducts = (data) => {
  return {
    tierChargifyProducts: data,
    type: MAIN_TYPES.LIST_TIER_CHARGIFY_PRODUCTS,
  }
}

export const listTiers = (data) => {
  return {
    tiers: data,
    type: MAIN_TYPES.LIST_TIERS,
  }
}

export const listAllTiers = (data) => {
  return {
    allTiers: data,
    type: MAIN_TYPES.LIST_ALL_TIERS,
  }
}

export const listTiersChargifyRelationships = (data) => {
  return {
    tiersChargifyRelationships: data,
    type: MAIN_TYPES.LIST_TIERS_CHARGIFY_RELATIONSHIPS,
  }
}

export const updateSearchTierChargifyFields = (data) => {
  return {
    data,
    type: MAIN_TYPES.UPDATE_SEARCH_TIER_CHARGIFY_FIELDS,
  }
}

// CHARGIFY
export const resetTiersMeta = () => {
  return {
    type: MAIN_TYPES.RESET_TIER_CHARGIFY_PRODUCTS,
  }
}

// TIERS
export const resetTieringMeta = () => {
  return {
    type: MAIN_TYPES.RESET_TIERING_CHARGIFY_PRODUCTS,
  }
}
