// @flow
import React, { useState } from 'react'

type Props = {|
  defaultPlaceholder?: string,
  name?: string,
  className: string,
  currentValue: string,
  maxLength?: number,
  error?: object,
  inputRef: () => void,
  handleBlur: () => void,
  handleFocus: () => void,
  handleChange: () => void,
  isDisabled: Boolean,
|}

/**
 * Convert to decimal value number includes commas
 * @param {Number} value
 */
export const convertToDecimal = (value) => {
  if (value) {
    return value
      .toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return ''
}

/**
 * Revert from decimal value number remove commas
 * @param {Number} value
 */
export const revertFromDecimal = (value) => {
  if (value) {
    return value.replace(/[, ]+/g, '').trim()
  }
  return ''
}

export const internalHook = ({ placeholder, onBlur, defaultValue }) => {
  const [defaultPlaceholder, updatePlaceholder] = useState(placeholder)
  const [currentValue, updateValue] = useState(convertToDecimal(defaultValue))

  const handleFocus = () => {
    updatePlaceholder('')
  }

  const handleBlur = (event) => {
    updatePlaceholder(placeholder)
    onBlur({
      target: {
        value: revertFromDecimal(currentValue),
        name: event.target.name,
      },
    })
  }

  const handleChange = (event) => {
    updateValue(convertToDecimal(event.target.value))
  }

  return {
    defaultPlaceholder,
    handleFocus,
    handleBlur,
    currentValue,
    handleChange,
  }
}

export const DecimalInput = ({
  error,
  className,
  name,
  isDisabled,
  handleChange,
  handleBlur,
  inputRef,
  defaultPlaceholder,
  handleFocus,
  currentValue,
  maxLength,
}: Props) => {
  const isError = error && error[name]

  return (
    <input
      className={`${className ? className : 'form__textbox'} ${
        isError ? 'form__textbox--error' : ''
      } ${isDisabled ? 'form__textbox--disabled' : ''}`}
      type='text'
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      ref={inputRef}
      name={name}
      placeholder={defaultPlaceholder}
      disabled={isDisabled}
      value={currentValue}
      maxLength={maxLength}
    />
  )
}

const DecimalInputWrapper = (props) => (
  <DecimalInput {...internalHook(props)} {...props} />
)

// Defined default Props
DecimalInputWrapper.defaultProps = {
  isError: false,
  maxlength: 100,
  isDisabled: false,
}

export default DecimalInputWrapper
