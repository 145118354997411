const MESSAGES = {
  SIGN_IN_FAILED: 'Sign in Failed !',
  GET_CURRENT_USER_FAILED: 'Get current user failed !',
  EMPTY_USERNAME: 'Please input Username',
  EMPTY_PASSWORD: 'Please input Password',
  SIGN_UP_FAILED: 'Sign up failed',
  VERIFICATION_FAILED: 'Verification code failed',
  GET_LIST_POST_FAILED: 'Get list posts failed',
  GET_USER_FAILED: 'Get user failed',
  ADD_POST_FAILED: 'Add post failed',
  EDIT_POST_FAILED: 'Edit posts failed',
  DELETE_POST_FAILED: 'Delete posts failed',
  ADD_COMMENT_FAILED: 'Add comment failed',
  DELETE_COMMENT_FAILED: 'Delete comment failed',
  SIGN_OUT_FAILED: 'Sign Out failed',
}

export default MESSAGES
