import { MAIN_TYPES } from 'contexts/actions/Main'

export const initialState = {
  programs: {
    list: [],
    loading: false,
    isProcessing: false,
    program: {},
    stuffs: {},
    isRefetchProgram: false,
    isRefetchDetail: false,
    isRefetchTemplate: false,
    search: {},
  },
  products: {
    list: [],
    loading: false,
    isProcessing: false,
  },
  releases: {
    list: [],
    loading: false,
    isProcessing: false,
    release: {},
    stuffs: {},
    isRefetchRelease: false,
    isRefetchReleases: true,
    isRefetchDetail: false,
    isRefetchTracks: false,
    offset: 0,
    limit: 50,
    total: 0,
    sort: {
      order: 'ASC',
      field_name: 'name',
    },
    search: {},
  },
  workouts: {
    list: [],
    loading: false,
    isProcessing: false,
    isRefetchWorkout: true,
    isRefetchWorkouts: true,
    isRefetchDetail: false,
    isRefetchTracks: false,
    isRefetchAssets: false,
    offset: 0,
    limit: 50,
    total: 0,
    workout: {},
    stuffs: {},
    sort: {
      order: 'ASC',
      field_name: 'name',
    },
    search: {},
    exclusiveName: '',
  },
  countries: {
    list: [],
    loading: false,
    isProcessing: false,
  },
  directors: {
    list: [],
    loading: false,
    isProcessing: false,
  },
  muscles: {
    list: [],
    loading: false,
    isProcessing: false,
  },
  moves: {
    list: [],
    loading: false,
    isProcessing: false,
    isRefetchMoves: true,
    offset: 0,
    limit: 100,
    total: 0,
    stuffs: {},
    sort: {
      order: 'ASC',
      field_name: 'name',
    },
    search: {},
  },
  equipments: {
    list: [],
    loading: false,
    isProcessing: false,
    stuffs: {},
  },
  musics: {
    list: [],
    loading: false,
    isProcessing: false,
  },
  musicLabels: {
    list: [],
    loading: false,
    isProcessing: false,
    stuffs: {},
  },
  presenters: {
    list: [],
    loading: false,
    isProcessing: false,
    stuffs: {},
  },
  plans: {
    list: [],
    loading: false,
    isProcessing: false,
    plan: {},
    stuffs: {},
    isRefetchPlan: false,
    isRefetchDetail: false,
    isRefetchSchedules: false,
    search: {},
  },
  collections: {
    list: [],
    collection: {},
    isRefetchCollections: true,
    isRefetchDetail: false,
    isProcessing: false,
    offset: 0,
    limit: 50,
    total: 0,
    stuffs: {},
    sort: {
      order: 'ASC',
      field_name: 'name',
    },
    search: {},
  },
  collectionTypes: {
    list: [],
    loading: false,
    isProcessing: false,
  },
  publishings: {
    list: [],
    isProcessing: false,
    publishing: {},
    stuffs: {},
    isRefetched: true,
    isCheckingStatus: false,
  },
  tierChargifyProducts: {
    list: [],
    offset: 0,
    limit: 10,
    total: 0,
    search: {},
  },
  tiers: {
    list: [],
    offset: 0,
    limit: 3,
    total: 0,
    search: {},
  },
  allTiers: {
    list: [],
  },
  type: '',
  error: {},
}

const saveListData = (state, action, type, key) => {
  return {
    ...state,
    [key]: {
      ...state[key],
      list: action[key],
    },
    type,
  }
}

const saveData = (state, action, type, key) => {
  return {
    ...state,
    [key]: {
      ...state[key],
      ...action.data,
    },
    type,
  }
}

const saveSingleData = (state, action, type, key) => {
  const pluralKey = key + 's'
  return {
    ...state,
    [pluralKey]: {
      ...state[pluralKey],
      [key]: {
        ...state[pluralKey][key],
        ...action.data,
      },
    },
    type,
  }
}

export const processApi = (state, type, key, isEnd) => {
  return {
    ...state,
    [key]: {
      ...state[key],
      isProcessing: !isEnd,
    },
    type,
  }
}

export const resetType = (state, key) => {
  const pluralKey = key + 's'
  return {
    ...state,
    [pluralKey]: {
      ...state[pluralKey],
      [key]: {},
      stuffs: {},
      isProcessing: false,
    },
    type: '',
  }
}

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAIN_TYPES.ON_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case MAIN_TYPES.GET_LIST_PROGRAMS:
      return {
        ...state,
        programs: {
          ...state.programs,
          list: action.programs,
        },
        type: MAIN_TYPES.GET_LIST_PROGRAMS,
      }

    case MAIN_TYPES.GET_LIST_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          list: action.products,
        },
        type: MAIN_TYPES.GET_LIST_PRODUCTS,
      }

    case MAIN_TYPES.GET_LIST_COUNTRIES:
      return {
        ...state,
        countries: {
          ...state.countries,
          list: action.countries,
        },
        type: MAIN_TYPES.GET_LIST_COUNTRIES,
      }

    case MAIN_TYPES.GET_LIST_DIRECTORS:
      return {
        ...state,
        directors: {
          ...state.directors,
          list: action.directors,
        },
        type: MAIN_TYPES.GET_LIST_DIRECTORS,
      }

    case MAIN_TYPES.GET_LIST_MUSCLES:
      return {
        ...state,
        muscles: {
          ...state.muscles,
          list: action.muscles,
        },
        type: MAIN_TYPES.GET_LIST_MUSCLES,
      }

    case MAIN_TYPES.CREATE_PROGRAM_SUCCESS:
      return {
        ...state,
        programs: {
          ...state.programs,
          program: action.response,
        },
        type: MAIN_TYPES.CREATE_PROGRAM_SUCCESS,
      }

    case MAIN_TYPES.RESET_TYPE:
      return {
        ...state,
        programs: {
          ...state.programs,
          program: {},
          isProcessing: false,
        },
        type: '',
      }

    // Process program api
    case MAIN_TYPES.PROCESSING_API:
      return {
        ...state,
        programs: {
          ...state.programs,
          isProcessing: true,
        },
        type: MAIN_TYPES.PROCESSING_API,
      }

    // End process program api
    case MAIN_TYPES.PROCESSING_API_END:
      return {
        ...state,
        programs: {
          ...state.programs,
          isProcessing: false,
        },
        type: MAIN_TYPES.PROCESSING_API_END,
      }

    case MAIN_TYPES.GET_LIST_EQUIPMENTS:
      return {
        ...state,
        equipments: {
          ...state.equipments,
          list: action.equipments,
        },
        type: MAIN_TYPES.GET_LIST_EQUIPMENTS,
      }

    case MAIN_TYPES.GET_PROGRAM_DETAIL:
    case MAIN_TYPES.SAVE_PROGRAM_MASTER_CLASS_TEMPLATE:
    case MAIN_TYPES.SAVE_PROGRAM_MASTER_CLASS_TRACKS:
    case MAIN_TYPES.SAVE_PROGRAM_MASTER_CLASS_VARIANTS:
      return saveSingleData(state, action, action.type, 'program')

    case MAIN_TYPES.UPDATE_SEARCH_PROGRAM_FIELDS:
      return saveData(state, action, action.type, 'programs')

    case MAIN_TYPES.SAVE_PROGRAM_REFETCH_FLAG:
      return saveData(state, action, action.type, 'programs')

    case MAIN_TYPES.STORE_LIST_MUSICS:
      return {
        ...state,
        musics: {
          ...state.musics,
          list: action.musics,
        },
        type: MAIN_TYPES.STORE_LIST_MUSICS,
      }

    case MAIN_TYPES.CREATE_RELEASE_SUCCESS:
      return {
        ...state,
        releases: {
          ...state.releases,
          release: action.response,
        },
        type: MAIN_TYPES.CREATE_RELEASE_SUCCESS,
      }

    // RELEASES
    case MAIN_TYPES.GET_LIST_RELEASES:
      return saveListData(state, action, action.type, 'releases')

    case MAIN_TYPES.GET_RELEASE_DETAIL:
    case MAIN_TYPES.UPDATE_MUSIC_SUCCESS:
    case MAIN_TYPES.CREATE_MUSIC_SUCCESS:
    case MAIN_TYPES.CREATE_RELEASE_TRACK_SUCCESS:
    case MAIN_TYPES.UPDATE_RELEASE_TRACK_SUCCESS:
      return saveSingleData(state, action, action.type, 'release')

    case MAIN_TYPES.RESET_RELEASE_TYPE:
      return resetType(state, 'release')

    case MAIN_TYPES.PROCESSING_RELEASE_API:
      return processApi(state, action.type, 'releases', false)

    case MAIN_TYPES.PROCESSING_RELEASE_API_END:
      return processApi(state, action.type, 'releases', true)

    case MAIN_TYPES.SAVE_RELEASE_REFETCH_FLAG:
    case MAIN_TYPES.SAVE_RELEASE_LIST_META:
    case MAIN_TYPES.UPDATE_SORT_RELEASE_FIELDS:
    case MAIN_TYPES.UPDATE_SEARCH_RELEASE_FIELDS:
      return saveData(state, action, action.type, 'releases')

    case MAIN_TYPES.RESET_RELEASE_META:
      return {
        ...state,
        releases: {
          list: [],
          loading: false,
          isProcessing: false,
          release: {},
          isRefetchRelease: false,
          isRefetchReleases: true,
          isRefetchDetail: false,
          isRefetchTracks: false,
          offset: 0,
          limit: 50,
          total: 0,
          sort: {
            order: 'ASC',
            field_name: 'name',
          },
          search: {},
        },
      }

    // WORKOUT ACTIONS
    case MAIN_TYPES.GET_WORKOUT_DETAIL:
    case MAIN_TYPES.CREATE_WORKOUT_SUCCESS:
    case MAIN_TYPES.SAVE_WORKOUT_MUSICS:
    case MAIN_TYPES.SAVE_WORKOUT_TRACKS:
    case MAIN_TYPES.UPDATE_WORKOUT_SUCCESS:
    case MAIN_TYPES.UPDATE_WORKOUT_MUSICS_SUCCESS:
    case MAIN_TYPES.UPDATE_WORKOUT_TRACKS_SUCCESS:
      return saveSingleData(state, action, action.type, 'workout')

    case MAIN_TYPES.SAVE_WORKOUT_REFETCH_FLAG:
    case MAIN_TYPES.SAVE_WORKOUT_LIST_META:
    case MAIN_TYPES.UPDATE_SORT_WORKOUT_FIELDS:
    case MAIN_TYPES.UPDATE_SEARCH_WORKOUT_FIELDS:
      return saveData(state, action, action.type, 'workouts')

    case MAIN_TYPES.RESET_WORKOUT_META:
      return {
        ...state,
        workouts: {
          list: [],
          loading: false,
          isProcessing: false,
          isRefetchWorkout: true,
          isRefetchWorkouts: true,
          isRefetchDetail: false,
          isRefetchTracks: false,
          isRefetchAssets: false,
          offset: 0,
          limit: 50,
          total: 0,
          workout: {},
          sort: {
            order: 'ASC',
            field_name: 'name',
          },
          search: {},
          exclusiveName: '',
        },
      }

    // WORKOUT
    case MAIN_TYPES.GET_LIST_WORKOUTS:
      return saveListData(state, action, action.type, 'workouts')

    case MAIN_TYPES.PROCESSING_WORKOUT_API:
      return processApi(state, action.type, 'workouts', false)

    case MAIN_TYPES.PROCESSING_WORKOUT_API_END:
      return processApi(state, action.type, 'workouts', true)

    case MAIN_TYPES.RESET_WORKOUT_TYPE:
      return resetType(state, 'workout')

    case MAIN_TYPES.SAVE_WORKOUT_VIMEO_URL:
      return {
        ...state,
        workouts: {
          ...state.workouts,
          stuffs: {
            ...state.workouts.stuffs,
            ...action.data,
          },
          isProcessing: false,
        },
        type: action.type,
      }

    case MAIN_TYPES.SAVE_WORKOUT_STUFFS:
      return {
        ...state,
        workouts: {
          ...state.workouts,
          stuffs: {
            ...state.workouts.stuffs,
            ...action.data,
          },
        },
        type: action.type,
      }

    // MOVES
    case MAIN_TYPES.GET_LIST_MOVES:
      return saveListData(state, action, action.type, 'moves')

    case MAIN_TYPES.GET_MOVE_DETAIL:
    case MAIN_TYPES.CREATE_MOVE_SUCCESS:
    case MAIN_TYPES.CREATE_MOVE_REQUEST:
      return saveSingleData(state, action, action.type, 'move')

    case MAIN_TYPES.RESET_MOVE_TYPE:
      return resetType(state, 'move')

    case MAIN_TYPES.PROCESSING_MOVE_API:
      return processApi(state, action.type, 'moves', false)

    case MAIN_TYPES.PROCESSING_MOVE_API_END:
      return processApi(state, action.type, 'moves', true)

    case MAIN_TYPES.SAVE_MOVE_LIST_META:
    case MAIN_TYPES.SAVE_MOVE_REFETCH_FLAG:
    case MAIN_TYPES.UPDATE_SORT_MOVE_FIELDS:
    case MAIN_TYPES.UPDATE_SEARCH_MOVE_FIELDS:
      return saveData(state, action, action.type, 'moves')

    case MAIN_TYPES.RESET_MOVE_META:
      return {
        ...state,
        moves: {
          list: [],
          loading: false,
          isProcessing: false,
          isRefetchMoves: true,
          offset: 0,
          limit: 100,
          total: 0,
          sort: {
            order: 'ASC',
            field_name: 'name',
          },
          search: {},
        },
      }

    // MUSIC LABEL
    case MAIN_TYPES.GET_LIST_MUSIC_LABELS:
      return saveListData(state, action, action.type, 'musicLabels')

    case MAIN_TYPES.GET_MUSIC_LABEL_DETAIL:
    case MAIN_TYPES.CREATE_MUSIC_LABEL_SUCCESS:
      return saveSingleData(state, action, action.type, 'musicLabel')

    case MAIN_TYPES.RESET_MUSIC_LABEL_TYPE:
      return resetType(state, 'musicLabel')

    case MAIN_TYPES.PROCESSING_MUSIC_LABEL_API:
      return processApi(state, action.type, 'musicLabels', false)

    case MAIN_TYPES.PROCESSING_MUSIC_LABEL_API_END:
      return processApi(state, action.type, 'musicLabels', true)

    // PRESENTER
    case MAIN_TYPES.GET_LIST_PRESENTERS:
      return saveListData(state, action, action.type, 'presenters')

    case MAIN_TYPES.GET_PRESENTER_DETAIL:
    case MAIN_TYPES.CREATE_PRESENTER_SUCCESS:
      return saveSingleData(state, action, action.type, 'presenter')

    case MAIN_TYPES.RESET_PRESENTER_TYPE:
      return resetType(state, 'presenter')

    case MAIN_TYPES.PROCESSING_PRESENTER_API:
      return processApi(state, action.type, 'presenters', false)

    case MAIN_TYPES.PROCESSING_PRESENTER_API_END:
      return processApi(state, action.type, 'presenters', true)

    // EQUIPMENT
    case MAIN_TYPES.GET_EQUIPMENT_DETAIL:
    case MAIN_TYPES.CREATE_EQUIPMENT_SUCCESS:
      return saveSingleData(state, action, action.type, 'equipment')

    case MAIN_TYPES.RESET_EQUIPMENT_TYPE:
      return resetType(state, 'equipment')

    case MAIN_TYPES.PROCESSING_EQUIPMENT_API:
      return processApi(state, action.type, 'equipments', false)

    case MAIN_TYPES.PROCESSING_EQUIPMENT_API_END:
      return processApi(state, action.type, 'equipments', true)

    // COLLECTIONS
    case MAIN_TYPES.GET_LIST_COLLECTIONS:
      return saveListData(state, action, action.type, 'collections')
    case MAIN_TYPES.UPDATE_SORT_COLLECTION_FIELDS:
    case MAIN_TYPES.SAVE_COLLECTION_LIST_META:
    case MAIN_TYPES.UPDATE_SEARCH_COLLECTION_FIELDS:
    case MAIN_TYPES.SAVE_COLLECTION_REFETCH_FLAG:
      return saveData(state, action, action.type, 'collections')
    case MAIN_TYPES.GET_COLLECTION_DETAIL:
      return saveSingleData(state, action, action.type, 'collection')
    case MAIN_TYPES.RESET_COLLECTION_TYPE: {
      return resetType(state, 'collection')
    }
    case MAIN_TYPES.GET_COLLECTION_TYPES:
      return {
        ...state,
        collectionTypes: {
          ...state.collectionTypes,
          list: action.collectionTypes,
        },
        type: MAIN_TYPES.GET_COLLECTION_TYPES,
      }
    case MAIN_TYPES.PROCESSING_COLLECTION_API:
      return processApi(state, action.type, 'collections', false)

    case MAIN_TYPES.PROCESSING_COLLECTION_API_END:
      return processApi(state, action.type, 'collections', true)
    case MAIN_TYPES.CREATE_COLLECTION_SUCCESS:
      return saveSingleData(state, action, action.type, 'collection')

    case MAIN_TYPES.UPDATE_WORKOUTS_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          collection: {
            ...state.collections.collection,
            workouts: action.data,
          },
        },
        type: action.type,
      }

    case MAIN_TYPES.RESET_COLLECTION_META:
      return {
        ...state,
        collections: {
          list: [],
          collection: {},
          isRefetchCollections: true,
          isRefetchDetail: false,
          isProcessing: false,
          offset: 0,
          limit: 50,
          total: 0,
          stuffs: {},
          sort: {
            order: 'ASC',
            field_name: 'name',
          },
          search: {},
        },
      }

    // PLANS
    case MAIN_TYPES.GET_LIST_PLANS:
      return saveListData(state, action, action.type, 'plans')

    case MAIN_TYPES.GET_PLAN_DETAIL:
    case MAIN_TYPES.CREATE_PLAN_SUCCESS:
    case MAIN_TYPES.SAVE_SCHEDULE_SUCCESS:
      return saveSingleData(state, action, action.type, 'plan')

    case MAIN_TYPES.UPDATE_SEARCH_PLAN_FIELDS:
      return saveData(state, action, action.type, 'plans')

    case MAIN_TYPES.PROCESSING_PLAN_API:
      return processApi(state, action.type, 'plans', false)

    case MAIN_TYPES.PROCESSING_PLAN_API_END:
      return processApi(state, action.type, 'plans', true)

    case MAIN_TYPES.RESET_PLAN_TYPE: {
      const currentState = resetType(state, 'plan')
      return {
        ...currentState,
        plans: {
          ...currentState.plans,
          search: state.plans.search,
        },
      }
    }

    case MAIN_TYPES.SAVE_PLAN_REFETCH_FLAG:
      return saveData(state, action, action.type, 'plans')

    // PUBLISHING
    case MAIN_TYPES.GET_LIST_PUBLISHING:
      return saveListData(state, action, action.type, 'publishings')

    case MAIN_TYPES.GET_PUBLISHING_DETAIL:
    case MAIN_TYPES.CREATE_PUBLISHING_SUCCESS:
    case MAIN_TYPES.FINISH_PUBLISH_STEP:
    case MAIN_TYPES.START_PUBLISH_STEP:
      return saveSingleData(state, action, action.type, 'publishing')

    case MAIN_TYPES.RESET_PUBLISHING_TYPE: {
      const result = resetType(state, 'publishing')
      return {
        ...result,
        publishings: {
          ...result.publishings,
          list: [],
          isRefetched: true,
          isCheckingStatus: false,
          publishing: {
            step: 1,
            env: 'test',
          },
        },
      }
    }

    case MAIN_TYPES.PROCESSING_PUBLISHING_API:
      return processApi(state, action.type, 'publishings', false)

    case MAIN_TYPES.PROCESSING_PUBLISHING_API_END:
      return processApi(state, action.type, 'publishings', true)

    case MAIN_TYPES.SAVE_PUBLISHING_REFETCH_FLAG:
      return saveData(state, action, action.type, 'publishings')

    // TIERING
    case MAIN_TYPES.RESET_TIERING_CHARGIFY_PRODUCTS:
      return {
        ...state,
        allTiers: {
          list: [],
        },
      }

    case MAIN_TYPES.LIST_ALL_TIERS:
      return saveListData(state, action, action.type, 'allTiers')

    // TIER CHARGIFY PRODUCTS
    case MAIN_TYPES.UPDATE_SEARCH_TIER_CHARGIFY_FIELDS:
      return saveData(state, action, action.type, 'tierChargifyProducts')

    case MAIN_TYPES.RESET_TIER_CHARGIFY_PRODUCTS:
      return {
        ...state,
        tierChargifyProducts: {
          list: [],
        },
        tiers: {
          list: [],
        },
        tiersChargifyRelationships: {
          list: [],
        },
      }

    case MAIN_TYPES.LIST_TIER_CHARGIFY_PRODUCTS:
      return saveListData(state, action, action.type, 'tierChargifyProducts')

    case MAIN_TYPES.LIST_TIERS:
      return saveListData(state, action, action.type, 'tiers')

    case MAIN_TYPES.LIST_TIERS_CHARGIFY_RELATIONSHIPS:
      return saveListData(
        state,
        action,
        action.type,
        'tiersChargifyRelationships'
      )

    case MAIN_TYPES.RESET_DATA:
      return initialState

    default:
      return state
  }
}

export default mainReducer
