import gql from 'graphql-tag'

export const queryProducts = gql`
  query ListProducts {
    listProducts {
      id
      name
      created
      update_prod
      update_uat
    }
  }
`
