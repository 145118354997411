import { USER_TYPES } from 'contexts/actions/User'

import MESSAGES from 'constants/Messages'

export const initialState = {
  inProcess: {
    isLoading: false,
    error: {},
  },
  switchUser: {},
  user: {},
  type: '',
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPES.CHANGE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
        type: action.type,
      }

    case USER_TYPES.IN_PROCESS:
    case USER_TYPES.SIGN_IN:
    case USER_TYPES.SWITCH_ACC:
      return {
        ...state,
        inProcess: {
          isLoading: true,
          error: {},
        },
        type: action.type,
      }

    case USER_TYPES.SIGN_IN_SUCCESS:
    case USER_TYPES.GET_CURRENT_SUCCESS:
      return {
        ...state,
        user: action.user,
        switchUser: action.user,
        inProcess: {
          ...state.inProcess,
          isLoading: false,
        },
        type: action.type,
      }

    case USER_TYPES.SWITCH_ACC_SUCCESS:
      return {
        ...state,
        switchUser: action.user,
        inProcess: {
          ...state.inProcess,
          isLoading: false,
        },
        type: action.type,
      }

    case USER_TYPES.SIGN_IN_FAILED:
    case USER_TYPES.SWITCH_ACC_FAILED:
      return {
        ...state,
        user: {},
        switchUser: {},
        inProcess: {
          isLoading: false,
          error: action.error,
        },
        type: action.type,
      }

    case USER_TYPES.GET_CURRENT_FAILED:
    case USER_TYPES.SIGN_OUT_SUCCESS:
      return {
        ...state,
        user: {},
        switchUser: {},
        inProcess: {
          isLoading: false,
          error: {},
        },
        type: action.type,
      }

    case USER_TYPES.SIGN_OUT_FAILED:
      return {
        ...state,
        inProcess: {
          isLoading: false,
          error: {
            message: MESSAGES.SIGN_OUT_FAILED,
          },
        },
        type: action.type,
      }

    case USER_TYPES.RESET_ERROR:
      return {
        ...state,
        inProcess: {
          isLoading: false,
          error: {},
        },
      }

    case USER_TYPES.RESET_TYPE:
      return {
        ...state,
        inProcess: {
          isLoading: false,
          error: {},
        },
        type: '',
      }

    default:
      return state
  }
}

export default userReducer
