// @flow
import React from 'react'

// Component
import Input from 'components/input'
import TextArea from 'components/textArea'

type Props = {|
  placeholder?: string,
  defaultValue?: string,
  autoComplete: string,
  name?: string,
  type?: string,
  className: string,
  typeInput: string,
  propKey?: string,
  isTextArea: boolean,
  isDisabled: boolean,
  showIcon: boolean,
  isShowPassword: boolean,
  subTitle?: string,
  maxlength?: number,
  error?: object,
  inputRef: () => void,
  onBlur: () => void,
  onFocus: () => void,
  onClick: () => void,
  isUrl: boolean,
|}

const InputGroup = (props: Props) => {
  const {
    placeholder,
    defaultValue,
    inputRef,
    name,
    subTitle,
    isTextArea,
    maxlength,
    error,
    onBlur,
    onFocus,
    type,
    propKey,
    isDisabled,
    className,
    typeInput,
    autoComplete,
    showIcon,
    isShowPassword,
    onClick,
    isUrl,
  } = props

  const isError = error && error[name]

  return (
    <div className='form__input-group'>
      <div className='form__input-group--wrapper'>
        {subTitle && (
          <label className='page-content__form__input-title'>{subTitle}</label>
        )}
        {isError && <p className='form__text--error'>{error[name]}</p>}
      </div>
      {isTextArea ? (
        <TextArea
          type='text'
          placeholder={placeholder}
          name={name}
          inputRef={inputRef}
          defaultValue={defaultValue}
          onBlur={onBlur}
          required
          maxLength={maxlength}
          error={error}
          key={propKey}
          className={className}
          isDisabled={isDisabled}
        />
      ) : (
        <Input
          type={type}
          placeholder={placeholder}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          defaultValue={defaultValue}
          inputRef={inputRef}
          required
          maxLength={maxlength}
          error={error}
          key={propKey}
          isDisabled={isDisabled}
          className={className}
          typeInput={typeInput}
          autoComplete={autoComplete}
          isUrl={isUrl}
        />
      )}
      {showIcon && (
        <i onClick={onClick} className='material-icons icon-eyes'>
          {isShowPassword ? 'visibility_off' : 'visibility'}
        </i>
      )}
    </div>
  )
}

// Defined default Props
InputGroup.defaultProps = {
  isError: false,
  type: 'text',
  isTextArea: false,
  maxlength: 500,
  inputRef: () => {},
}

export default InputGroup
