//@flow
import React from 'react'

// Components
import InputGroup from 'components/inputGroup'
import Select from 'components/select'

type Props = {|
  title: string,
  className: string,
  type: string,
  data: object,
  error: object,
|}

const FormRow = ({ title, className, type, data, error }: Props) => {
  return (
    <div className={`page-content__row ${className}`}>
      {type !== 'textbox' ? (
        <>
          <div className='form__input-group--wrapper'>
            <label className='page-content__form__input-title'>{title}</label>
            {error && error[data.name] && (
              <p className='form__text--error'>{error[data.name]}</p>
            )}
          </div>
          <Select
            defaultValue={data.defaultValue}
            isMulti={data.isMulti}
            name={data.name}
            options={data.options}
            placeholder={data.placeholder}
            onChange={data.onChange}
            selectRef={data.ref}
            getOptionLabel={
              data.getOptionLabel
                ? data.getOptionLabel
                : (option) => option[data.labelField || 'label']
            }
            getOptionValue={
              data.getOptionValue
                ? data.getOptionValue
                : (option) => option[data.keyField || 'value']
            }
            key={data.key}
            error={error}
            isDisabled={!!data.isDisabled}
            onFocus={data.onFocus ? data.onFocus : () => {}}
          />
        </>
      ) : (
        <InputGroup
          placeholder={data.placeholder}
          defaultValue={data.defaultValue}
          inputRef={data.ref}
          subTitle={title}
          onBlur={data.onChange}
          name={data.name}
          isTextArea={
            data.name === 'description' || data.name.indexOf('description') > -1
          }
          maxlength={data.maxlength}
          error={error}
          type={data.type || 'text'}
          onFocus={data.onFocus}
          propKey={data.key}
          isDisabled={data.isDisabled}
          className={data.className}
          typeInput={data.typeInput}
          autoComplete={data.autoComplete}
          showIcon={data.showIcon}
          isShowPassword={data.isShowPassword}
          onClick={data.onClick}
          isUrl={data.isUrl}
        />
      )}
    </div>
  )
}

FormRow.defaultProps = {
  title: '',
  className: '',
  type: 'textbox',
  data: {},
}

export default FormRow
