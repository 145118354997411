//@flow
import React from 'react'

type Props = {|
  size?: 'big' | 'small',
  isInternal?: Boolean,
  className?: string,
  style: Object,
|}

const Indicator = ({ size, isInternal, className }: Props) => (
  <div
    className={`loading-indicator ${
      isInternal ? 'loading-indicator--internal' : ''
    } ${className}`}
  >
    <div className={`preloader-wrapper active ${size}`}>
      <div className='spinner-layer spinner-green-only'>
        <div className='circle-clipper left'>
          <div className='circle' />
        </div>
        <div className='gap-patch'>
          <div className='circle' />
        </div>
        <div className='circle-clipper right'>
          <div className='circle' />
        </div>
      </div>
    </div>
  </div>
)

export const Spinner = ({ style, className }: Props) => (
  <div className='spinner--tiny preloader-wrapper small active' style={style}>
    <div className={`spinner-layer spinner-green-only ${className}`}>
      <div className='circle-clipper left'>
        <div className='circle' />
      </div>
      <div className='gap-patch'>
        <div className='circle' />
      </div>
      <div className='circle-clipper right'>
        <div className='circle' />
      </div>
    </div>
  </div>
)

Indicator.defaultProps = {
  size: 'big',
  isInternal: false,
  className: '',
}

export default Indicator
