// @flow
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import MainRouter from 'router'
import { UserProvider } from 'contexts/providers/User'
import { MainProvider } from 'contexts/providers/Main'

import './index.scss'
import 'config/Amplify'

const titles = {
  dev: 'Les Mills CMDS - Dev Dashboard',
  test: 'Les Mills CMDS - Test Dashboard',
  prod: 'Les Mills CMDS - Production Dashboard',
}

class App extends Component {
  render() {
    return (
      <>
        <UserProvider>
          <MainProvider>
            <div id='root-wrapper'>
              <MainRouter />
            </div>
          </MainProvider>
        </UserProvider>
        <Helmet>
          <title>{titles[process.env.REACT_APP_ENV]}</title>
        </Helmet>
      </>
    )
  }
}

export default App
