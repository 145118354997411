// @flow
import React, { PureComponent } from 'react'
import _ from 'lodash'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'

// Layout
import { DashboardLayout } from 'layouts'

// Constants and helpers
import ROUTER from 'constants/Router'
import { UserContext } from 'contexts/providers/User'
import { connects } from 'contexts/helpers/Connect'
import client from 'config/Apollo'
import LazyLoader from 'helpers/LazyLoader'

// SIGN IN
import SignIn from 'pages/sign-in'

type Props = {|
  user: object,
  onGetCurrentUser: () => {},
  isLoading: boolean,
|}

// PROGRAM
const Programs = React.lazy(() => import('pages/programs'))
const ProgramFormContainer = React.lazy(() =>
  import('pages/programs/components/ProgramFormContainer')
)

// RELEASE
const Releases = React.lazy(() => import('pages/releases'))
const ReleaseForm = React.lazy(() =>
  import('pages/releases/components/ReleaseFormContainer')
)

// WORKOUT
const Workouts = React.lazy(() => import('pages/workouts'))
const WorkoutForm = React.lazy(() =>
  import('pages/workouts/components/WorkoutFormContainer')
)

// PLAN
const Plans = React.lazy(() => import('pages/plans'))
const PlanForm = React.lazy(() =>
  import('pages/plans/components/PlanFormContainer')
)

// COLLECTION
const Collections = React.lazy(() => import('pages/collections'))
const CollectionForm = React.lazy(() =>
  import('pages/collections/components/CollectionFormContainer')
)

// PRODUCT
const Products = React.lazy(() => import('pages/products'))
const ProductForm = React.lazy(() =>
  import('pages/products/components/ProductForm')
)

// COMBINE FORM
const Presenters = React.lazy(() => import('pages/presenters/Container'))
const MusicLicensing = React.lazy(() =>
  import('pages/music-licensing/Container')
)
const Moves = React.lazy(() => import('pages/moves/Container'))
const Equipments = React.lazy(() => import('pages/equipments/Container'))

// REPORT
const Reports = React.lazy(() => import('pages/reports'))
const ReportForm = React.lazy(() =>
  import('pages/reports/components/ReportForm')
)

// PUBLISHING
const PublishingContainer = React.lazy(() => {
  const publishingPath =
    process.env.REACT_APP_RT_LPE_30 === 'true'
      ? import('pages/publishing_new/Container')
      : import('pages/publishing/PublishingContainer')
  return publishingPath
})

// PUBLISHING PLAN
const PublishPlans = React.lazy(() => import('pages/publish-plans/Container'))

// TIERING
const Tiers = React.lazy(() => import('pages/tiers'))
const TiersForm = React.lazy(() => import('pages/tiers/components/TiersForm'))

// TIER RELATIONSHIPS
const TierChargifyProduct = React.lazy(() =>
  import('pages/tier-chargify-product')
)
const TierChargifyProductForm = React.lazy(() =>
  import('pages/tier-chargify-product/components/TierChargifyProductForm')
)
const TierChargifyMultipleProductForm = React.lazy(() =>
  import(
    'pages/tier-chargify-product/components/TierChargifyMultipleProductForm'
  )
)
const DeleteTierChargifyRelationship = React.lazy(() =>
  import(
    'pages/tier-chargify-product/components/DeleteTierChargifyRelationship'
  )
)

// NOT FOUND
const NotFound = React.lazy(() => import('pages/not-found'))

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return !_.isEmpty(rest.user) && rest.user.accessToken ? (
          <Component {...props} user={rest.user} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTER.SIGN_IN,
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

class MainRouter extends PureComponent<Props> {
  render() {
    const { user } = this.props

    return (
      <ApolloProvider client={client}>
        <Router>
          <DashboardLayout isPrivate={!_.isEmpty(user) && user.accessToken}>
            <Switch>
              <Route exact path={ROUTER.SIGN_IN} component={SignIn} />
              <PrivateRoute
                exact
                path={ROUTER.HOME}
                component={LazyLoader(Releases)}
                user={user}
              />

              {/* Program routes */}
              <PrivateRoute
                exact
                path={ROUTER.PROGRAMS}
                component={LazyLoader(Programs)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.ADD_PROGRAM}
                component={LazyLoader(ProgramFormContainer)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.PROGRAM}
                component={LazyLoader(ProgramFormContainer)}
                user={user}
              />

              {/* Release routes */}
              <PrivateRoute
                exact
                path={ROUTER.RELEASES}
                component={LazyLoader(Releases)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.ADD_RELEASE}
                component={LazyLoader(ReleaseForm)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.RELEASE}
                component={LazyLoader(ReleaseForm)}
                user={user}
              />

              {/* Workout routes */}
              <PrivateRoute
                exact
                path={ROUTER.WORKOUTS_ROUTER}
                component={LazyLoader(Workouts)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.ADD_WORKOUT_ROUTER}
                component={LazyLoader(WorkoutForm)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.EDIT_WORKOUT_ROUTER}
                component={LazyLoader(WorkoutForm)}
                user={user}
              />

              {/* Presenter routes */}
              <PrivateRoute
                exact
                path={ROUTER.PRESENTERS}
                component={LazyLoader(Presenters)}
                user={user}
              />

              {/* Music-licensing routes */}
              <PrivateRoute
                exact
                path={ROUTER.MUSIC_LICENSINGS}
                component={LazyLoader(MusicLicensing)}
                user={user}
              />

              {/* Moves routes */}
              <PrivateRoute
                exact
                path={ROUTER.MOVES}
                component={LazyLoader(Moves)}
                user={user}
              />

              {/* Equipments routes */}
              <PrivateRoute
                exact
                path={ROUTER.EQUIPMENTS}
                component={LazyLoader(Equipments)}
                user={user}
              />

              {/* Products routes */}
              <PrivateRoute
                exact
                path={ROUTER.PRODUCTS}
                component={LazyLoader(Products)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.ADD_PRODUCT}
                component={LazyLoader(ProductForm)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.PRODUCT}
                component={LazyLoader(ProductForm)}
                user={user}
              />

              {/* Plans routes */}
              <PrivateRoute
                exact
                path={ROUTER.PLANS}
                component={LazyLoader(Plans)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.ADD_PLAN}
                component={LazyLoader(PlanForm)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.PLAN}
                component={LazyLoader(PlanForm)}
                user={user}
              />

              {/* Collections routes */}
              <PrivateRoute
                exact
                path={ROUTER.COLLECTIONS}
                component={LazyLoader(Collections)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.ADD_COLLECTION}
                component={LazyLoader(CollectionForm)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.COLLECTION}
                component={LazyLoader(CollectionForm)}
                user={user}
              />

              {/* Reports routes */}
              <PrivateRoute
                exact
                path={ROUTER.REPORTS}
                component={LazyLoader(Reports)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.ADD_REPORT}
                component={LazyLoader(ReportForm)}
                user={user}
              />

              {/* Publishing routes */}
              <PrivateRoute
                exact
                path={ROUTER.PUBLISHING}
                component={LazyLoader(PublishingContainer)}
                user={user}
              />

              {/* Publishing plan routes */}
              <PrivateRoute
                exact
                path={ROUTER.PUBLISH_PLANS}
                component={LazyLoader(PublishPlans)}
                user={user}
              />

              {/* Tiering routes */}
              <PrivateRoute
                exact
                path={ROUTER.TIERS}
                component={LazyLoader(Tiers)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.ADD_TIER}
                component={LazyLoader(TiersForm)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.TIER}
                component={LazyLoader(TiersForm)}
                user={user}
              />

              {/* Tier-relationship-product route */}
              <PrivateRoute
                exact
                path={ROUTER.DELETE_TIER_CHARGIFY_RELATIONSHIP}
                component={LazyLoader(DeleteTierChargifyRelationship)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.EDIT_TIER_CHARGIFY_PRODUCTS}
                component={LazyLoader(TierChargifyMultipleProductForm)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.TIER_CHARGIFY_PRODUCTS}
                component={LazyLoader(TierChargifyProduct)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.ADD_TIER_CHARGIFY_PRODUCT}
                component={LazyLoader(TierChargifyProductForm)}
                user={user}
              />
              <PrivateRoute
                exact
                path={ROUTER.TIER_CHARGIFY_PRODUCT}
                component={LazyLoader(TierChargifyProductForm)}
                user={user}
              />

              <Route component={LazyLoader(NotFound)} />
            </Switch>
          </DashboardLayout>
        </Router>
      </ApolloProvider>
    )
  }
}

const mapStateToProps = (state = []) => {
  const [userState] = state
  return {
    user: userState.user,
  }
}

export default connects([UserContext], mapStateToProps, null)(MainRouter)
