//@flow
import React, { createContext, useReducer } from 'react'
import userReducer, { initialState } from 'contexts/reducers/User'
import {
  KEY_LOCAL_STORAGE_USER_STATE,
  usePersistedContext,
  usePersistedReducer,
} from 'contexts/persist'

type Props = {|
  children: React.Node,
|}

export const UserContext = createContext()

export const UserProvider = ({ children }: Props) => {
  // create a global store to store the user state
  const stateStore = usePersistedContext(KEY_LOCAL_STORAGE_USER_STATE)
  const stateApp = stateStore ? stateStore : initialState

  // global will be a state manager to manage user state.
  const value = usePersistedReducer(
    useReducer(userReducer, stateApp),
    KEY_LOCAL_STORAGE_USER_STATE // The localStorage user key
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
