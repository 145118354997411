import React, { PureComponent, Suspense } from 'react'
import Indicator from 'components/indicator'

function LazyLoader(WrappedComponent) {
  return class Wrapped extends PureComponent {
    render() {
      return (
        <Suspense fallback={<Indicator />}>
          <WrappedComponent {...this.props} />
        </Suspense>
      )
    }
  }
}

export default LazyLoader
