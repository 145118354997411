import React from 'react'

function customMultiValueLabel(MultiValueLabel, isShowToolTip) {
  class CustomMultiValueLabel extends React.Component {
    render() {
      return (
        <MultiValueLabel {...this.props}>
          {isShowToolTip ? (
            <span
              className='tooltipped'
              data-position='top'
              data-tooltip={this.props.children}
            >
              {this.props.children}
            </span>
          ) : (
            this.props.children
          )}
        </MultiValueLabel>
      )
    }
  }
  return CustomMultiValueLabel
}

export default customMultiValueLabel
