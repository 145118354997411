export const APPLY_API_PAGES = ['workout', 'release', 'move', 'collection']
export const DIMENSION_IMG = {
  workout: {
    min: '1340x760',
    max: '1340x760',
  },
  program: {
    min: '1340x688',
    max: '1340x688',
  },
  plan: {
    min: '1344x1000',
    max: '1344x1000',
  },
}

export const DIMENSION_PROG_ICON = {
  'LES MILLS BARRE': {
    min: '231x75',
    max: '231x75',
  },
  BODYATTACK: {
    min: '288x75',
    max: '288x75',
  },
  BODYBALANCE: {
    min: '330x75',
    max: '330x75',
  },
  BODYCOMBAT: {
    min: '309x75',
    max: '309x75',
  },
  BODYFLOW: {
    min: '252x75',
    max: '252x75',
  },
  BODYPUMP: {
    min: '264x75',
    max: '264x75',
  },
  BODYSTEP: {
    min: '228x75',
    max: '228x75',
  },
  'BORN TO MOVE': {
    min: '210x75',
    max: '210x75',
  },
  CXWORX: {
    min: '204x75',
    max: '204x75',
  },
  'LES MILLS GRIT': {
    min: '156x75',
    max: '156x75',
  },
  RPM: {
    min: '186x75',
    max: '186x75',
  },
  // eslint-disable-next-line quotes
  "SH'BAM": {
    min: '186x75',
    max: '186x75',
  },
  'LES MILLS SPRINT': {
    min: '195x75',
    max: '195x75',
  },
  'THE TRIP': {
    min: '306x75',
    max: '306x75',
  },
  'LES MILLS TRAINING': {
    min: '306x75',
    max: '306x75',
  },
  'GRIT CARDIO': {
    min: '108x75',
    max: '108x75',
  },
  'GRIT STRENGTH': {
    min: '108x75',
    max: '108x75',
  },
  'GRIT PLYO': {
    min: '108x75',
    max: '108x75',
  },
}

export const expandedWidth = 235
export const collapsedWidth = 50
