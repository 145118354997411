//@flow
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import Indicator from 'components/indicator'

// Constants
import { NAVIGATION } from 'constants/Navigations'
import ROUTER from 'constants/Router'
import { ICONS } from 'assets/icons/index.js'
import { expandedWidth, collapsedWidth } from 'constants/Common'
import { IMAGES } from 'assets/images'

// Context
import { MainContext } from 'contexts/providers/Main'
import { connects } from 'contexts/helpers/Connect'

import { checkMenuIsActive } from 'helpers/Utilities'

type Props = {|
  accessWorkout: boolean,
  expandWorkoutSubMenu: () => {},
  onToggleAside: () => {},
  products: Array,
  productsLoading: boolean,
  pathName: String,
|}

// Only show these 3 products as sub menu for workout
const mainProducts = ['lmod']

export const internalHook = ({ products }) => {
  // State show product workout
  const [accessWorkout, markAccessWorkout] = useState(false)
  const pathName = window.location.pathname

  useEffect(
    () => {
      // Expand sub menu if path name starts with '/workout'
      if (pathName.startsWith('/workout')) {
        products.length > 0 && markAccessWorkout(true)
      } else {
        markAccessWorkout(false)
      }
    },
    /*eslint no-sparse-arrays: off */
    [, products, pathName]
  )

  return {
    pathName,
    accessWorkout,
    markAccessWorkout,
  }
}

/**
 *
 * @param {object} item - menu item
 * @param {int} index - index of menu item in list
 * @param {bool} isParent - is parent item
 * @param {bool} isShowSubMenu - is expanding sub menu
 */
const renderLinkItem = (
  item,
  index,
  isParent,
  isShowSubMenu,
  markAccessWorkout,
  products,
  pathName,
  accessWorkout
) => {
  // Get Bike product from list
  const lmodProduct = products.find(
    (item) => item.name.toLowerCase() === mainProducts[0]
  )

  // Default click on Bike product for workout url
  const route = item.hasSubMenu
    ? `${item.route}/${lmodProduct ? lmodProduct.id : '1'}`
    : item.route

  return (
    <Link
      to={{
        pathname: route,
        state: { from: pathName },
      }}
      className={`aside__item__link ${
        isParent && !isShowSubMenu && !item.hasSubMenu
          ? 'aside__item__link--active'
          : ''
      }`}
      data-index={index}
      onClick={
        item.hasSubMenu ? () => markAccessWorkout(!accessWorkout) : () => {}
      }
    >
      <span className='aside__item__link__left' data-index={index}>
        {item.name}
      </span>
      {item.hasSubMenu && (
        <img
          src={accessWorkout ? ICONS.arrowDown : ICONS.arrowRight}
          alt='Arrow Right'
          className='aside__item__link__arrow'
        />
      )}
    </Link>
  )
}

const Aside = ({
  accessWorkout,
  markAccessWorkout,
  products,
  productsLoading,
  pathName,
  asideWidth,
  onToggleAside,
}: Props) => {
  const id = pathName.split('/')[2]
  const groupNav = _.groupBy(NAVIGATION, 'group')

  if (productsLoading) {
    return <Indicator />
  }

  return (
    <>
      <ul className='aside'>
        <button className='aside__btn-toggle' onClick={onToggleAside}>
          <i className='tiny material-icons'>{`${
            asideWidth === expandedWidth ? 'arrow_back' : 'arrow_forward'
          }`}</i>
        </button>
        {asideWidth > collapsedWidth && (
          <>
            <Link
              to={{
                pathname: '/',
                state: { from: pathName },
              }}
              className='aside__main-logo'
            >
              <img src={IMAGES.logo} alt='LesMills Logo' />
            </Link>
            <div className='aside__group-container'>
              {Object.keys(groupNav).map((group) => {
                return (
                  <li className='aside__group' key={group}>
                    {group}
                    <ul>
                      {groupNav[group].map((item, index) => {
                        const currentPath =
                          pathName === '/' ? ROUTER.RELEASES : pathName
                        const isActiveMenu = checkMenuIsActive(
                          currentPath,
                          item.key
                        )
                        const isAddForm =
                          isActiveMenu && pathName.indexOf('add') > -1

                        return (
                          <li
                            key={index}
                            className={`aside__item ${
                              (isActiveMenu || isAddForm) && !item.hasSubMenu
                                ? 'aside__item--active'
                                : ''
                            } ${
                              item.hasSubMenu ? 'aside__item--has-sub-menu' : ''
                            }`}
                          >
                            {renderLinkItem(
                              item,
                              index,
                              true,
                              false,
                              markAccessWorkout,
                              products,
                              pathName,
                              accessWorkout
                            )}
                            {accessWorkout && item.key === 'workouts' ? (
                              <ul>
                                {products
                                  .filter(
                                    (p) =>
                                      mainProducts.indexOf(
                                        p.name.toLowerCase()
                                      ) > -1
                                  )
                                  .map((product, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className={`aside__item  ${
                                          id === product.id.toString() &&
                                          pathName.indexOf('workout') > -1
                                            ? 'aside__item--active'
                                            : 'aside-item--workout'
                                        }`}
                                      >
                                        <Link
                                          to={{
                                            pathname: `/workouts/${product.id}`,
                                            state: { from: pathName },
                                          }}
                                          className='aside__item__link aside-workout-link'
                                        >
                                          {product.name}
                                        </Link>
                                      </li>
                                    )
                                  })}
                              </ul>
                            ) : (
                              ''
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}
            </div>
          </>
        )}
      </ul>
    </>
  )
}

const AsideWrapper = (props) => <Aside {...internalHook(props)} {...props} />

const mapStateToProps = (state = []) => {
  const [mainState] = state
  return {
    products: mainState.products ? mainState.products.list : [],
  }
}

export default connects([MainContext], mapStateToProps, null)(AsideWrapper)
