import arrowRight from './arrow-right.svg'
import arrowDown from './arrow-down.svg'
import musicLicensing from './music-licensing.svg'
import presenters from './presenters.svg'
import moves from './moves.svg'
import equipment from './equipment.svg'
import editWorkout from './edit-workout.svg'
import addWorkout from './add-workout.svg'
import editProgram from './edit-program.svg'
import addProgram from './add-program.svg'
import listProgram from './list-program.svg'

export const ICONS = {
  listProgram,
  addProgram,
  editProgram,
  addWorkout,
  editWorkout,
  equipment,
  moves,
  presenters,
  musicLicensing,
  arrowRight,
  arrowDown,
}
