import React, { Component } from 'react'
import { Spinner } from '../indicator'

export const CHECK_TIMEOUT = 300

export const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
  const bottomBorder = scrollHeight - clientHeight
  return bottomBorder === scrollTop
}

export default function customMenuList(
  MenuList,
  allowAdd = false,
  onCreate = () => {},
  isLoading = false
) {
  class CustomMenuList extends Component {
    constructor(props) {
      super(props)
      this.state = {
        scrollTop: 0,
      }
    }

    componentDidMount() {
      if (this.menuListRef) {
        this.menuListRef.scrollTop =
          this.menuListRef.scrollHeight - this.menuListRef.clientHeight - 30
      }

      this.setCheckAndHandleTimeout()
    }

    componentWillUnmount() {
      if (this.checkTimeout) {
        clearTimeout(this.checkTimeout)
      }
    }

    innerRef = (ref) => {
      if (ref === this.menuListRef) {
        return
      }

      this.menuListRef = ref
      this.props.innerRef(ref)
    }

    setCheckAndHandleTimeout = () => {
      if (this.props.selectProps.isfetchMore) {
        this.checkAndHandle()
        this.checkTimeout = setTimeout(
          this.setCheckAndHandleTimeout,
          CHECK_TIMEOUT
        )
      }
    }

    checkAndHandle() {
      if (this.shouldHandle()) {
        const {
          selectProps: { handleScrolledToBottom },
        } = this.props

        if (handleScrolledToBottom) {
          handleScrolledToBottom()
        }
      }
    }

    shouldHandle() {
      const el = this.menuListRef

      if (!el) {
        return false
      }

      const { scrollTop, scrollHeight, clientHeight } = el
      const shouldScroll = scrollTop > 0 && scrollTop !== this.state.scrollTop

      if (shouldScroll) {
        this.setState({
          scrollTop,
        })
        return shouldLoadMore(scrollHeight, clientHeight, scrollTop)
      }

      return shouldScroll
    }

    render() {
      return (
        <>
          {isLoading && (
            <div className='menu-list-content__overlay'>
              <Spinner className='menu-list-content__spinner' />
            </div>
          )}
          <MenuList
            {...this.props}
            innerRef={this.innerRef}
            className={`${isLoading ? 'menu-list-content--loading' : ''}`}
          >
            {this.props.children}

            {allowAdd && (
              <button
                className='btn btn-action--small btn-add'
                type='button'
                onClick={onCreate}
              >
                <i className='material-icons'>add_circle</i>
                Add as New
              </button>
            )}
          </MenuList>
        </>
      )
    }
  }

  return CustomMenuList
}
