import { Auth } from 'aws-amplify'
import ApolloClient from 'apollo-boost'

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  request: async (operation) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    operation.setContext({
      headers: {
        authorization: token,
      },
    })
  },
})

export default client
