// @flow
import React, { useState } from 'react'

type Props = {|
  defaultPlaceholder?: string,
  defaultValue?: string,
  className: string,
  name?: string,
  type?: string,
  maxlength?: number,
  error?: object,
  style: object,
  inputRef: () => void,
  handleBlur: () => void,
  handleFocus: () => void,
  isDisabled: boolean,
|}

export const internalHook = ({ placeholder, onBlur }) => {
  const [defaultPlaceholder, updatePlaceholder] = useState(placeholder)

  const handleFocus = () => {
    updatePlaceholder('')
  }

  const handleBlur = (event) => {
    updatePlaceholder(placeholder)
    onBlur(event)
  }

  return {
    defaultPlaceholder,
    handleFocus,
    handleBlur,
  }
}

export const TextArea = (props: Props) => {
  const {
    className,
    defaultPlaceholder,
    name,
    defaultValue,
    maxlength,
    type,

    error,

    handleBlur,
    handleFocus,
    inputRef,
    isDisabled,
  } = props

  const isError = error && error[name]

  return (
    <textarea
      className={`form__textarea ${className ? className : ''} ${
        isError ? 'form__textarea--error' : ''
      } ${isDisabled ? 'form__textarea--disabled' : ''}
      `}
      type={type}
      placeholder={defaultPlaceholder}
      name={name}
      ref={inputRef}
      defaultValue={defaultValue}
      onBlur={handleBlur}
      onFocus={handleFocus}
      required
      data-gramm_editor='false'
      maxLength={maxlength}
      disabled={isDisabled}
    />
  )
}

const TextAreaWrapper = (props) => (
  <TextArea {...internalHook(props)} {...props} />
)

// Defined default Props
TextAreaWrapper.defaultProps = {
  isError: false,
  type: 'text',
  maxlength: 500,
  inputRef: () => {},
}

export default TextAreaWrapper
