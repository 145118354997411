// @flow
import React, { useState } from 'react'

// Components
import DecimalInput from './DecimalInput'
import UrlInput from './UrlInput'

type Props = {|
  defaultPlaceholder?: string,
  defaultValue?: string,
  autoComplete: string,
  value: string,
  name?: string,
  type?: string,
  key: string,
  min: string,
  className: string,
  maxLength?: number,
  error?: object,
  style: object,
  inputRef: () => void,
  handleBlur: () => void,
  handleFocus: () => void,
  onKeyDown: () => void,
  onChange: () => void,
  onClick: () => void,
  autoFocus: Boolean,
  readonly: Boolean,
  isDisabled: Boolean,
|}

export const internalHook = ({
  placeholder,
  onBlur,
  onChange,
  typeInput,
  isUrl,
}) => {
  const [defaultPlaceholder, updatePlaceholder] = useState(placeholder)

  const handleFocus = () => {
    updatePlaceholder('')
  }

  const handleBlur = (event) => {
    updatePlaceholder(placeholder)

    onBlur &&
      onBlur({
        ...event,
        target: {
          ...event.target,
          value: event.target.value,
          name: event.target.name,
          type: typeInput,
          isUrl: isUrl,
        },
      })
  }

  const handleChange = (event) => {
    onChange &&
      onChange({
        ...event,
        target: {
          ...event.target,
          value: event.target.value,
          name: event.target.name,
          type: typeInput,
        },
      })
  }

  return {
    defaultPlaceholder,
    handleFocus,
    handleBlur,
    handleChange,
  }
}

export const Input = (props: Props) => {
  const {
    className,
    defaultPlaceholder,
    name,
    defaultValue,
    maxLength,
    value,
    key,
    min,
    type,
    autoFocus,
    error,
    style,
    onKeyDown,
    handleBlur,
    handleFocus,
    handleChange,
    inputRef,
    onClick,
    isDisabled,
    typeInput,
    onBlur,
    title,
    readonly,
    autoComplete,
  } = props

  const isError = error && error[name]

  switch (typeInput) {
    case 'decimal':
      return (
        <DecimalInput
          placeholder={defaultPlaceholder}
          inputRef={inputRef}
          onBlur={onBlur}
          error={error}
          isDisabled={isDisabled}
          name={name}
          title={title}
          defaultValue={defaultValue}
        />
      )
    case 'url':
      return (
        <UrlInput
          title={title}
          error={error}
          isDisabled={isDisabled}
          onBlur={onBlur}
          defaultValue={defaultValue}
          maxLength={maxLength}
          name={name}
          onFocus={handleFocus}
          inputRef={inputRef}
        />
      )
    default:
      return (
        <input
          className={`${className ? className : 'form__textbox'} ${
            isError ? 'form__textbox--error' : ''
          } ${isDisabled ? 'form__textbox--disabled' : ''}`}
          type={type}
          onKeyDown={onKeyDown}
          placeholder={defaultPlaceholder}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          defaultValue={defaultValue}
          ref={inputRef}
          required
          maxLength={maxLength}
          style={style}
          onChange={handleChange}
          value={value}
          onClick={onClick}
          key={key}
          min={min}
          autoFocus={autoFocus}
          disabled={isDisabled}
          readOnly={readonly}
          autoComplete={autoComplete}
        />
      )
  }
}

const InputWrapper = (props) => <Input {...internalHook(props)} {...props} />

// Defined default Props
InputWrapper.defaultProps = {
  isError: false,
  typeInput: 'text',
  maxlength: 500,
  inputRef: () => {},
}

export default InputWrapper
