import { Auth } from 'aws-amplify'
import _ from 'lodash'

import {
  signInFailed,
  signInSuccess,
  getCurrentUserSuccess,
  getCurrentUserFailed,
  signOutSuccess,
  signOutFailed,
} from 'contexts/actions/User'

import { VALID_USERS } from 'constants/UserRoles'

const getUserInfo = (currentSession, user, env) => {
  return {
    accessToken:
      currentSession.accessToken && currentSession.accessToken.jwtToken,
    refreshToken:
      currentSession.refreshToken && currentSession.refreshToken.token,
    id:
      currentSession.accessToken &&
      currentSession.accessToken.payload &&
      currentSession.accessToken.payload.sub,
    email: user.username,
    groups:
      currentSession.accessToken &&
      currentSession.accessToken.payload &&
      currentSession.accessToken.payload['cognito:groups'],
    env: env ? env : process.env.REACT_APP_ENV,
  }
}
/**
 * Performing sign in to AWS Cognito
 * @param {Object} user
 * @param {Object} dispatch
 */
export const signInCognito = (user = {}, dispatch = () => {}) => {
  if (
    _.isEmpty(user) ||
    (!_.isEmpty(user) && (!user.username || !user.password))
  ) {
    return
  }

  Auth.signIn(user.username, user.password)
    .then((data) => {
      const signInUserSession = data.signInUserSession
        ? data.signInUserSession
        : {}

      const groups =
        signInUserSession.accessToken && signInUserSession.accessToken.payload
          ? signInUserSession.accessToken.payload['cognito:groups']
          : []

      // Check if this acc is valid or not
      if (
        !groups ||
        (groups && !groups.find((i) => VALID_USERS.indexOf(i) > -1))
      ) {
        dispatch(
          signInFailed({
            message: 'This user doesn\'t have permission to access.',
          })
        )
      } else {
        // Get access token and user id after login
        dispatch(signInSuccess(getUserInfo(signInUserSession, user)))
      }
    })
    .catch((error) => {
      dispatch(signInFailed(error))
    })
}

/**
 * Performing get current user exists in local
 * @param {Object} dispatch
 */
export const getCurrentUserCognito = (dispatch = () => {}) => {
  Auth.currentSession()
    .then((data) => {
      const user = {
        accessToken: data.accessToken && data.accessToken.jwtToken,
        refreshToken: data.refreshToken && data.refreshToken.token,
        id: data.idToken && data.idToken.payload && data.idToken.payload.sub,
      }
      dispatch(getCurrentUserSuccess(user))
    })
    .catch((error) => {
      dispatch(getCurrentUserFailed(error))
    })
}

/**
 * Performing sign out
 * @param {Object} dispatch
 */
export const signOutCognito = (dispatch = () => {}) => {
  Auth.signOut()
    .then(() => {
      dispatch(signOutSuccess())
    })
    .catch((error) => {
      dispatch(signOutFailed(error))
    })
}
